<div style="display:flex;height:100%" class="dx-widget">
  <div style="background-image:url('/assets/images/bg_left.jpg');width:136px"></div>
  <div style="flex:1;padding:2em;box-shadow: 0px 0px 35px 5px rgba(0,0,0,0.3);z-index:1">
    <app-header itslogoRoute="/preparation-dashboard"></app-header>
    <div style="display:flex;width:100%">
      <div style="flex-grow:1">
        <h3 class="dx-theme-accent-as-text-color">{{ 'preparation.dashboard.title' | translate }}</h3>
      </div>
      <div>
        <app-refresh-button (refresh)="refresh()"></app-refresh-button>
        &nbsp;
        <dx-button text="{{'cert.dashboard.button.logout'|translate}}" (onClick)="logout()"></dx-button>
      </div>
    </div>

    <div class="dx-field" style="margin-bottom: 1em">
      <div class="dx-field-label" style="width: 60pt">{{ 'preparation.dashboard.label.testcenter' | translate }}</div>
      <div class="dx-field-value" style="width: calc(100% - 60pt);">
        <dx-select-box (onValueChanged)="onValueChangedTC($event)" [dataSource]="testcenters" valueExpr="testcenterId"
          displayExpr="name" [value]="testcenterId"></dx-select-box>
      </div>
    </div>
    <dx-data-grid [dataSource]="tanGroups" keyExpr="id" [customizeColumns]="customizeColumns"
      (onToolbarPreparing)="onToolbarPreparing($event)" [hoverStateEnabled]="true" [columnAutoWidth]="true"
      [allowColumnResizing]="true" [focusedRowEnabled]="true">
      <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
      <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="false">
      </dxo-editing>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-filter-row [visible]="false"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
      <dxo-column-fixing [enabled]="false"></dxo-column-fixing>
      <dxi-column caption="{{ 'preparation.dashboard.grid.id' | translate }}" dataField="id" [allowEditing]="false"
        [allowFiltering]="false" [visible]="true" sortOrder="asc"></dxi-column>
      <dxi-column caption="{{ 'preparation.dashboard.grid.name' | translate }}" dataField="name" [allowEditing]="false"
        [allowSearch]="true" [allowFiltering]="false">
      </dxi-column>
      <dxi-column caption="{{ 'preparation.dashboard.grid.module_shortname' | translate }}" dataField="module.shortName"
        [allowFiltering]="true" [allowEditing]="false"></dxi-column>
      <dxi-column caption="{{ 'preparation.dashboard.grid.valid_to' | translate }}" dataField="validTo"
        [allowFiltering]="false" [allowEditing]="false" dataType="date"></dxi-column>
      <dxi-column caption="{{ 'preparation.dashboard.grid.tan_count' | translate }}" dataField="maxTans"
        [allowFiltering]="false" [allowEditing]="false"></dxi-column>
      <dxi-column caption="{{ 'preparation.dashboard.grid.finished_count' | translate }}" [allowFiltering]="false"
        dataField="tansummary.complete" [allowEditing]="false"></dxi-column>
      <dxi-column caption="{{ 'preparation.dashboard.grid.done' | translate }}" [allowEditing]="false"
        dataField="isDone" dataType="boolean">
        <dxo-header-filter [dataSource]="headerFilterDataDone"></dxo-header-filter>
      </dxi-column>
      <dxi-column caption="{{ 'preparation.dashboard.grid.password' | translate }}" dataField="password"
        [allowSorting]="false" [allowFiltering]="false"></dxi-column>
      <dxi-column type="buttons" caption=" ">
        <dxi-button text="{{ 'preparation.dashboard.button.open' | translate }}" hint="Open Exam" [onClick]="openExam"
          [visible]="isOpenExamVisible">
        </dxi-button>
        <dxi-button text="{{ 'preparation.dashboard.button.info' | translate }}" hint="Info Exam" [onClick]="openExam"
          [visible]="isInfoExamVisible">
        </dxi-button>
      </dxi-column>
      <dxi-column type="selection" caption="Select"></dxi-column>
    </dx-data-grid>
    <dx-popup [(visible)]="newExamVisible" [showTitle]="true" [width]="300" [height]="'auto'"
      title="{{'preparation.dashboard.dialog.new.title' | translate}}" [closeOnOutsideClick]="true">
      <div *dxTemplate="let data of 'content'">
        <form (submit)="generateTanGroup($event)">
          <dx-form id="newExamForm" [(formData)]="newExamFormData" validationGroup="newExamFormVG" [items]="newExamFormItems">
          </dx-form>
          <p>{{ allowanceInfo }}</p>
          <div style="padding-top:2em">
            <dx-button type="default" text="{{'preparation.dashboard.dialog.new.button.create' | translate}}"
              [useSubmitBehavior]="true" validationGroup="newExamFormVG">
            </dx-button>
          </div>
        </form>
      </div>
    </dx-popup>
  </div>
  <div style="background-image:url('/assets/images/bg_right.jpg');width:136px"></div>
</div>
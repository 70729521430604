import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { formatDate, formatNumber } from 'devextreme/localization';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { calculateMastery, filterUndefined, partition } from '../helper';
import * as Types from '../its-organizer-api.types.g';
import * as Q from './data.query.g';
import { IExam_Report_DataGQL } from './data.query.g';

type Candidate = Q.IExam_Report_DataQuery['exam']['candidates'][0];
type Objective = NonNullable<Candidate['objectives']>[0];

interface Module {
  name: string;
  scoreMax: number;
  mastery: number | undefined;
  hasMastery: boolean;
  candidates: Candidate[];
  objectives: Objective[];
}

@Component({
  selector: 'app-exam-report',
  templateUrl: './exam-report.component.html',
  styleUrls: ['./exam-report.component.css']
})
export class ExamReportComponent implements OnInit {

  public examDate?: string;
  public id: string;
  public tcName?: string;
  public tcKey?: string;

  public modules?: Module[];

  constructor(
    private readonly data: IExam_Report_DataGQL,
    private activatedRoute: ActivatedRoute,
  ) {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!id) {
      throw new Error('param id missing');
    }

    this.id = id;
  }

  formatStatus(tan: Candidate) {
    if (tan.status === Types.CandidateStatus.Open) {
      return 'Nicht teilgenommen';
    }
    if (tan.status === Types.CandidateStatus.Inprogress) {
      return 'Exam In Progress';
    }
    if (tan.grade) {
      return tan.grade;
    }
    return 'Teilgenommen';
  }

  formatNumber(val: number | undefined | null) {
    if (typeof val !== 'number') {
      return '';
    }
    return formatNumber(val, { precision: 2 });
  }

  formatPercent(o: { gained: number, max: number } | null | undefined) {
    if (!o) {
      return '';
    }
    if (!o.max) {
      return '';
    }
    return formatNumber(Math.round(100 * o.gained / o.max), {
      type: 'fixedPoint',
      precision: 2
    }) + '%';
  }

  async ngOnInit() {
    const r = await firstValueFrom(this.data.fetch({
      id: this.id,
    }));
    const tg = r.data.exam;

    this.tcName = tg.testcenter.name;
    this.tcKey = tg.testcenter.testcenterId;

    const candidates = tg.candidates.filter(x => x.status !== Types.CandidateStatus.Open);
    const modules = partition(candidates.map(x => x.module), x => x.id);
    const candidatesPerModuleMap = partition(candidates, x => x.module.id);

    this.modules = Array.from(modules.keys()).map(moduleId => {
      const candidatesPerModule = candidatesPerModuleMap.get(moduleId)!;
      const scoreMax = candidatesPerModule.map(x => x.score?.max ?? 0).reduce((a, b) => a > b ? a : b, 0);
      const mastery = calculateMastery(candidatesPerModule.map(x => x.score));
      const hasMastery = (mastery ?? 0) > 0;
      const objectives = new Map<string, Objective>();
      for (const tan of candidatesPerModule) {
        if (!tan.objectives) {
          continue;
        }
        for (const obj of tan.objectives) {
          if (objectives.has(obj.key)) {
            const tgt = objectives.get(obj.key)!;
            tgt.score.gained += obj.score.gained;
            tgt.score.pending += obj.score.pending;
            tgt.score.lost += obj.score.lost;
            tgt.score.max += obj.score.max;
          } else {
            objectives.set(obj.key, obj);
          }
        }
      }

      const retVal: Module = {
        name: modules.get(moduleId)![0].shortName,
        candidates: candidatesPerModule,
        objectives: Array.from(objectives.keys()).sort().map(x => objectives.get(x)!),
        scoreMax,
        hasMastery,
        mastery
      };
      return retVal;
    });

    const startDate = filterUndefined(tg.candidates.map(x => x.startTime)).sort((a, b) => a.localeCompare(b));
    this.examDate = startDate.length ? formatDate(moment(startDate[0]).toDate(), { type: 'longDate' }) : undefined;


  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { insertSpaces } from '../helper';
import { PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';

type TAN = Q.IGetTanGroupQuery['tanGroup']['tans'][0] & {
  formattedTan: string
  nr: number;
};

@Component({
  selector: 'app-tanoverview',
  templateUrl: './tanoverview.component.html',
  styleUrls: ['./tanoverview.component.css']
})
export class TanoverviewComponent implements OnInit {
  public tans?: TAN[];
  public groupName?: string;
  public id?: string;
  public module?: string;

  constructor(
    private readonly qSvc: Q.IGetTanGroupGQL,
    public svc: PreparationExamService,
    public router: Router,
    public route: ActivatedRoute) { }

  print() {
    window.print();
  }
  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      throw new Error('parameter id not set');
    }
    this.id = id;
    const password = this.svc.getTanGroupPassword(id);
    const r = await firstValueFrom(this.qSvc.fetch(
      {
        id,
        password,
      }));
    this.groupName = r.data.tanGroup.name;
    this.module = r.data.tanGroup.module.shortName;
    this.tans = r.data.tanGroup.tans.sort((a, b) => a.id.localeCompare(b.id)).map((x, idx) => Object.assign(x, {
      formattedTan: insertSpaces(x.tan),
      nr: idx + 1
    }));
  }

}

import { assertNever } from './dx_helper';
import { ITEM_STATUS } from './interfaces';

export function filterUndefined<X>(values: Array<X | null | undefined> | undefined | null): X[] {
    if (!values) {
        return [];
    }
    const retVal: X[] = [];
    for (const v of values) {
        if (v === null) {
            continue;
        }
        if (v === undefined) {
            continue;
        }
        retVal.push(v);
    }
    return retVal;
}

export function extract_date(date: Date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export function insertSpaces(token: string) {
    if (!token) {
        return '';
    }
    const retVal = [];
    const input = token.split('');
    for (let i = 0; i < input.length; ++i) {
        if (i % 2 === 0) {
            retVal.push(' ');
        }
        retVal.push(input[i]);
    }
    return retVal.join('').trim();
}

export function addSlash(str: string) {
    if (!str) {
        return '';
    }
    if (str.endsWith('/')) {
        return str;
    }
    return str + '/';
}

export function isItemDone(status: ITEM_STATUS) {
    switch (status) {
        case ITEM_STATUS.current:
        case ITEM_STATUS.postponed:
        case ITEM_STATUS.open:
            return false;
        case ITEM_STATUS.completed:
        case ITEM_STATUS.correct:
        case ITEM_STATUS.incorrect:
        case ITEM_STATUS.partiallyCorrect:
            return true;
        default:
            assertNever(status);
            throw new Error();
    }
}

export function ensureArray<T>(val: T | T[] | undefined | null): T[] {
    if (!val) {
        return [];
    }
    if (Array.isArray(val)) {
        return val;
    }
    return [val];
}

export function itemCompletionPercentage(str: string) {
    if (!str) {
        return 0;
    }
    const arr = <ITEM_STATUS[]>str.split('');
    const done = arr.filter(x => isItemDone(x));
    return done.length / arr.length * 100;
}

export function partition<T, TKey>(arr: T[], keyFn: (val: T) => TKey): Map<TKey, T[]> {
    const retVal = new Map<TKey, T[]>();
    if (arr) {
        for (const v of arr) {
            const key = keyFn(v);
            if (!retVal.has(key)) {
                retVal.set(key, []);
            }
            retVal.get(key)!.push(v);
        }
    }
    return retVal;
}

export function calculateMastery(scores: Array<{ mastery?: number | null | undefined } | undefined | null> | undefined | null): number | undefined {
    if (!scores) {
        return undefined;
    }
    const nonNullScores = filterUndefined(scores).filter(x => (x.mastery ?? 0) > 0);
    if (nonNullScores.length === 0) {
        return undefined;
    }
    if (nonNullScores.length === 1) {
        return nonNullScores[0].mastery!;
    }
    const masteryMax = nonNullScores.map(x => x.mastery!).reduce((a, b) => a > b ? a : b);
    const masteryMin = nonNullScores.map(x => x.mastery!).reduce((a, b) => a < b ? a : b);
    if (masteryMin !== masteryMax) {
        return undefined;
    }
    if (masteryMin > 0) {
        return Math.ceil(masteryMin);
    }
    return undefined;
}

<div style="display:flex;height:100%" class="dx-widget">
  <div style="background-image:url('/assets/images/bg_left.jpg');width:136px"></div>
  <div style="flex:1;overflow:auto;padding:2em;box-shadow: 0px 0px 35px 5px rgba(0,0,0,0.3);z-index:1">
    <app-header itslogoRoute="/cert-dashboard"></app-header>
    <div style="display:flex;width:100%">
      <div style="flex-grow:1">
        <h3 class="dx-theme-accent-as-text-color">{{ 'cert.dashboard.title' | translate }}</h3>
      </div>
      <div>
        <dx-button [(visible)]="isNewExamVisible" text="{{'cert.dashboard.button.newexam'|translate}}"
          (onClick)="newExam()"></dx-button>
        &nbsp;
        <app-refresh-button (refresh)="refresh()"></app-refresh-button>
        &nbsp;
        <dx-button text="{{'cert.dashboard.button.logout'|translate}}" (onClick)="logout()"></dx-button>
      </div>
    </div>
    <h2>
      <span>{{'cert.dashboard.header.current'|translate}}</span>&#32;
    </h2>

    <dx-data-grid [dataSource]="currentExams" keyExpr="id" [hoverStateEnabled]="true" [columnAutoWidth]="true"
      [allowColumnResizing]="true" [focusedRowEnabled]="true">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-editing mode="cell" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="false">
      </dxo-editing>
      <dxo-search-panel [visible]="false"></dxo-search-panel>
      <dxo-filter-row [visible]="showFilterCurrent"></dxo-filter-row>
      <dxo-header-filter [visible]="false"></dxo-header-filter>
      <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
      <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10,20,50,'all']"></dxo-pager>
      <dxo-column-fixing [enabled]="false"></dxo-column-fixing>
      <dxi-column caption="{{ 'cert.dashboard.grid.key' | translate }}" dataField="key" [allowEditing]="false"
        [width]="80" [allowFiltering]="false" [visible]="true"></dxi-column>
      <dxi-column [(visible)]="isNameVisible" caption="{{ 'cert.dashboard.grid.name' | translate }}" dataField="name" [width]="150">
      </dxi-column>
      <dxi-column [(visible)]="isCertificateVisible" caption="{{ 'cert.dashboard.grid.certificate' | translate }}" dataField="certificate" [width]="80">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.modules' | translate }}" dataField="moduleDisplay" [width]="100">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.date' | translate }}" dataField="date" dataType="date" [width]="100" sortOrder="desc">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.testcenter' | translate }}" dataField="testcenterDisplay"
        [width]="200">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.go' | translate }}" cellTemplate="go">
      </dxi-column>
      <div *dxTemplate="let cell of 'go'">
        <dx-button text="{{ 'cert.dashboard.grid.gobutton' | translate }}" (onClick)="goto(cell.data)"></dx-button>
      </div>
    </dx-data-grid>
    <h2>
      <span>{{'cert.dashboard.header.recent'|translate}}</span>
    </h2>
    <dx-data-grid [dataSource]="recentExams" keyExpr="id" [hoverStateEnabled]="true" [columnAutoWidth]="true"
      [allowColumnResizing]="true" [focusedRowEnabled]="true">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-editing mode="cell" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="false">
      </dxo-editing>
      <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10,20,50,'all']"></dxo-pager>
      <dxo-search-panel [visible]="false"></dxo-search-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="false"></dxo-header-filter>
      <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
      <dxo-column-fixing [enabled]="false"></dxo-column-fixing>
      <dxi-column caption="{{ 'cert.dashboard.grid.key' | translate }}" dataField="key" [allowEditing]="false"
        [width]="80" [allowFiltering]="false" [visible]="true"></dxi-column>
      <dxi-column [(visible)]="isNameVisible" caption="{{ 'cert.dashboard.grid.name' | translate }}" dataField="name" [width]="150">
      </dxi-column>
      <dxi-column [(visible)]="isCertificateVisible" caption="{{ 'cert.dashboard.grid.certificate' | translate }}" dataField="certificate" [width]="80">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.modules' | translate }}" dataField="moduleDisplay" [width]="100">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.date' | translate }}" dataField="date" dataType="date" [width]="100" sortOrder="desc">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.testcenter' | translate }}" dataField="testcenterDisplay"
        [width]="200">
      </dxi-column>
      <dxi-column caption="{{ 'cert.dashboard.grid.results' | translate }}" cellTemplate="results">
      </dxi-column>
      <div *dxTemplate="let cell of 'results'">
        <div *ngIf="hasFile(cell.data)">
          <dx-button text="{{cell.data.key}}.zip" (onClick)="downloadFile(cell.data)"></dx-button>
        </div>
        <div *ngIf="!hasFile(cell.data)">
          {{'cert.dashboard.message.notfinished'|translate}}
        </div>
      </div>
    </dx-data-grid>

  </div>
  <div style="background-image:url('/assets/images/bg_right.jpg');width:136px"></div>
  <dx-popup [(visible)]="showNewExam" title="{{ 'cert.dashboard.newexamdlg.title' | translate }}" [showTitle]="true"
    [width]="500" [height]="'auto'">
    <app-new-cert-exam (imported)="newExamImported($event)"></app-new-cert-exam>
  </dx-popup>
</div>
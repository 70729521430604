import { Injectable } from '@angular/core';
import { addSlash } from './helper';

declare global {
  interface Window {
    __CONFIG?: {
      API_URL?: string;
      IDM_ISSUER?: string;
      IDM_CLIENTID?: string;
      PROCTOR_LOGIN?: ProctorLoginMode;
      EXAM_OPEN_MODE?: ExamOpenMode;
      CERT_SAMPLE_XLSX_URL?: string;
      CERT_EXAM_NAME_MODE?: ExamNameMode;
      ITSCLIENT_INSTALL_URL?: string;
      LIMIT_FUTURE_EXAM_DAYS?: string;
    };
  }
}
export type ExamOpenMode = 'replace' | 'popup';
type ProctorLoginMode = 'direct' | 'sso' | 'direct+sso' | 'disabled';
type ExamNameMode = 'required' | 'optional' | 'hidden';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor(
  ) {

  }

  limitFutureExamDays(): number {
    const defaultValue = 14;
    const c = window.__CONFIG;
    if (!c) {
      console.warn('__CONFIG not loaded');
      return defaultValue;
    }
    if (!c.LIMIT_FUTURE_EXAM_DAYS) {
      return defaultValue;
    }
    const days = parseInt(c.LIMIT_FUTURE_EXAM_DAYS, 10);
    if (isNaN(days) || (days < 0)) {
      console.warn('__CONFIG.LIMIT_FUTURE_EXAM_DAYS must be >=0');
    }

    return days;
  }
  getExamNameMode(): ExamNameMode {
    const c = window.__CONFIG;
    if (!c) {
      console.warn('__CONFIG not loaded');
      return 'optional';
    }
    switch (c.CERT_EXAM_NAME_MODE) {
      case 'hidden':
        return 'hidden';
      case 'optional':
        return 'optional';
      case 'required':
        return 'required';
      default:
        return 'optional';
    }
  }

  getProctorLoginMode(): ProctorLoginMode {
    const c = window.__CONFIG;
    if (!c) {
      console.warn('__CONFIG not loaded');
      return 'direct';
    }
    const hasSSO = !!c.IDM_ISSUER;
    const mode = c.PROCTOR_LOGIN || 'direct+sso';
    switch (mode) {
      case 'disabled':
      case 'direct':
        return mode;
      case 'direct+sso':
        if (hasSSO) {
          return mode;
        } else {
          return 'direct';
        }
      case 'sso':
        if (hasSSO) {
          return mode;
        } else {
          return 'disabled';
        }
    }
    return 'disabled';
  }


  getIdmIssuer() {
    console.log('get issuer from window');
    const c = window.__CONFIG;
    if (!c) {
      console.log('__CONFIG not loaded');
      return undefined;
    }
    if (!c.IDM_ISSUER) {
      console.log('__CONFIG.IDM_ISSUER not set');
      return undefined;

    }
    console.log(`__CONFIG.IDM_ISSUER=${c.IDM_ISSUER}`);
    return c.IDM_ISSUER;
  }

  getIdmClientId() {
    const c = window.__CONFIG;
    if (!c) {
      console.log('__CONFIG not loaded');
      return undefined;
    }
    if (!c.IDM_CLIENTID) {
      console.log('__CONFIG.IDM_CLIENTID not set');
      return undefined;

    }
    console.log(`__CONFIG.IDM_CLIENTID=${c.IDM_CLIENTID}`);
    return c.IDM_CLIENTID;
  }

  hasIDM() {
    return !!this.getIdmClientId() && !!this.getIdmIssuer();
  }
  getApiUrl() {
    const configJsApiUrl = window && window.__CONFIG && window.__CONFIG.API_URL;
    console.log(`configJsApiUrl: ${configJsApiUrl}`);
    const url = configJsApiUrl || '../api/';
    const baseUrl = addSlash(url);
    console.log(`baseUrl: ${baseUrl}`);
    return baseUrl;
  }

  getGraphQLUrl() {
    return appendGraphQL(this.getApiUrl());
  }

}

function appendGraphQL(url: string) {
  if (!url) {
    return url;
  }
  if (url.endsWith('/graphql')) {
    return url;
  }
  return new URL('graphql', url).toString();
}

<div style="display:flex;height:100%" class="dx-widget">
  <div style="background-image:url('/assets/images/bg_left.jpg');width:136px"></div>
  <div style="flex:1;padding:2em;box-shadow: 0px 0px 35px 5px rgba(0,0,0,0.3);z-index:1">
    <app-header itslogoRoute=":back"></app-header>
    <div style="display:flex;width:100%;margin-top:14px;">
      <div style="flex-basis: 1;flex-grow: 1">
        <div class="dx-theme-accent-as-text-color" style="font-size:14px;font-weight: bold;margin-bottom:14px">
          {{ 'preparation.tangroup.title' | translate }}</div>
        <p>{{testcenterId}} / {{tanGroupName}} / {{ 'preparation.tangroup.valid_to'| translate }} {{validUntil}}</p>
      </div>
      <div>
        <app-refresh-button (refresh)="refresh($event)"></app-refresh-button>
        &nbsp;
        <dx-button text="Zurück" (onClick)="goBack()"></dx-button>
      </div>
    </div>
    <dx-tab-panel #tabPanel>
      <dxi-item title="{{'preparation.tangroup.tab.overview'|translate}}" class="tabcontainer">
        <dx-data-grid #dataGridOverview keyExpr="id" [dataSource]="tanDS" [focusedRowEnabled]="true"
          [allowColumnResizing]="true" [hoverStateEnabled]="true" (onCellPrepared)="onCellPrepared($event)"
          (onToolbarPreparing)="onOverviewToolbarPreparing($event)">

          <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="false">
          </dxo-editing>
          <dxo-export [enabled]="false" fileName="tan-liste"></dxo-export>
          <dxi-column caption="{{'preparation.tangroup.grid.id'|translate}}" dataField="id" [visible]="false"
            [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'preparation.tangroup.grid.nr'|translate}}" dataField="nr" width="30"
            [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'preparation.tangroup.grid.tan'|translate}}" dataField="tan" [allowEditing]="false"
            [customizeText]="tanCustomText" width="100">

          </dxi-column>
          <dxi-column caption="{{'preparation.tangroup.grid.used'|translate}}" dataField="tanUsed" dataType="boolean"
            [allowExporting]="false" [allowEditing]="false" width="100">

          </dxi-column>
          <dxi-column caption="{{'preparation.tangroup.grid.for'|translate}}" dataField="name">
          </dxi-column>
        </dx-data-grid>
      </dxi-item>
      <dxi-item title="{{'preparation.tangroup.tab.progress'|translate}}" class="tabcontainer">
        <dx-data-grid keyExpr="id" [dataSource]="tanDS" [focusedRowEnabled]="true" #dataGridProgress
          [hoverStateEnabled]="true" (onInitialized)="onInitDgProgress($event)"
          [customizeColumns]="customizeProgressColumns" (onCellPrepared)="onCellPrepared($event)"
          [allowColumnReordering]="false" [allowColumnResizing]="true"
          (onToolbarPreparing)="onProgressToolbarPreparing($event)" [columnResizingMode]="'widget'" width="auto">
          <dxo-export [enabled]="false"></dxo-export>
          <dxi-column caption="{{'preparation.tangroup.grid.id'|translate}}" dataField="id" [visible]="false">
          </dxi-column>
          <dxi-column caption="{{'preparation.tangroup.grid.nr'|translate}}" dataField="nr" width="30">

          </dxi-column>
          <dxi-column caption="{{'preparation.tangroup.grid.tan'|translate}}" dataField="tan"
            [customizeText]="tanCustomText" width="100">

          </dxi-column>
          <dxi-column caption="{{'preparation.tangroup.grid.for'|translate}}" dataField="name" width="100">
          </dxi-column>
          <dxo-summary [calculateCustomSummary]="calculateIncorrect">
            <dxi-total-item column="tan" summaryType="count"
              displayFormat="{{ 'preparation.tangroup.grid.incorrect' | translate }}">
            </dxi-total-item>
          </dxo-summary>
          <div *dxTemplate="let cell of 'itemstatus'">
            <img class="stateicon" *ngIf="!!getImage(cell)" [src]="getImage(cell)">
          </div>
        </dx-data-grid>
        <div class="legend">
          <div *ngIf="hasCorrect"><img src="/assets/images/item_correct.svg">
            {{ 'preparation.tangroup.status.correct' | translate }}</div>
          <div *ngIf="hasIncorrect"><img src="/assets/images/item_incorrect.svg">
            {{ 'preparation.tangroup.status.incorrect' | translate }}
          </div>
          <div *ngIf="hasPartiallyCorrect"><img src="/assets/images/item_partiallycorrect.svg">
            {{ 'preparation.tangroup.status.partiallycorrect' | translate }}</div>
          <div *ngIf="hasOpen"><img src="/assets/images/item_open.svg">
            {{ 'preparation.tangroup.status.open' | translate }}</div>
          <div *ngIf="hasCompleted"><img src="/assets/images/item_completed.svg">{{
            'preparation.tangroup.status.completed' | translate }}
          </div>
          <div *ngIf="hasPostponed"><img src="/assets/images/item_postponed.svg">{{
            'preparation.tangroup.status.postponed' | translate }}
          </div>
          <div *ngIf="hasCurrent"><img src="/assets/images/item_current.svg">{{ 'preparation.tangroup.status.current' |
            translate }}</div>
        </div>
      </dxi-item>
      <dxi-item title="{{'preparation.tangroup.tab.results'|translate}}" class="tabcontainer">
        <dx-box direction="row" width="100%" [height]="'100%'">
          <dxi-item ratio="1">

            <dx-data-grid (onFocusedRowChanged)="filesOnFocusedRowChanged($event)" keyExpr="id" [dataSource]="tanDS"
              [focusedRowEnabled]="true" [hoverStateEnabled]="true" [allowColumnResizing]="true"
              (onCellPrepared)="onCellPrepared($event)" (onToolbarPreparing)="onResultsToolbarPreparing($event)">
              <dxo-export [enabled]="false"></dxo-export>
              <dxi-column caption="{{'preparation.tangroup.grid.id'|translate}}" dataField="id" [visible]="false">
              </dxi-column>
              <dxi-column caption="{{'preparation.tangroup.grid.nr'|translate}}" dataField="nr" width="30">
              </dxi-column>
              <dxi-column caption="{{'preparation.tangroup.grid.tan'|translate}}" dataField="tan"
                [customizeText]="tanCustomText" width="100">
              </dxi-column>
              <dxi-column caption="{{'preparation.tangroup.grid.for'|translate}}" dataField="name">
              </dxi-column>
              <dxi-column caption="{{'preparation.tangroup.grid.barometer'|translate}}" dataField="score" width="120"
                cellTemplate="score">
              </dxi-column>
              <!--
              <dxi-column caption="{{'preparation.tangroup.grid.results'|translate}}" cellTemplate="result">
              </dxi-column>
              <div *dxTemplate="let cell of 'result'">
                <div *ngIf="hasResult(cell.data)">
                  <dx-button text="{{ cell.data.tan +'.pdf' }}" hint="Open Exam"
                    (onClick)="openCandidatePdf(cell.data)">
                  </dx-button>
                </div>
              </div>
              -->
              <div *dxTemplate="let cell of 'score'">
                <div *ngIf="cell.data.score; then thenBlock else elseBlock">
                </div>
                <ng-template #thenBlock>
                  <app-score [score]="cell.data.score"></app-score>

                </ng-template>
                <ng-template #elseBlock></ng-template>
              </div>
            </dx-data-grid>
            <dx-data-grid [dataSource]="files" [allowColumnResizing]="true">

              <dxi-column caption="{{ 'preparation.tangroup.grid.result' | translate}}" dataField="text" width="130">
              </dxi-column>
              <dxi-column caption="{{ 'preparation.tangroup.grid.file' | translate}}" cellTemplate="file"></dxi-column>
              <div *dxTemplate="let cell of 'file'">
                <div *ngIf="hasFile(cell.data)">
                  <dx-button text="{{cell.data.filename}}" (onClick)="openFile(cell.data)"></dx-button>
                </div>
              </div>
            </dx-data-grid>
          </dxi-item>
          <dxi-item [baseSize]="10" class="spacer">
            <div>&nbsp;</div>
          </dxi-item>
          <dxi-item ratio="2">
            <div class="detail">
              <div *ngIf="hasResult(focusedTan);then detailedTanResult else noTanResult"></div>
              <ng-template #noTanResult>
                <div class="detailHeader"><b>{{'preparation.tangroup.label.detailview'|translate}}</b></div>
                <div style="padding:5em">{{'preparation.tangroup.hint.detailview'|translate}}
                </div>
              </ng-template>
              <ng-template #detailedTanResult>
                <div class="detailHeader"><b>{{'preparation.tangroup.label.detailview'|translate}}</b>
                  {{focusedTan!.tan}} / {{focusedTan!.name}}</div>
                <div style="padding-left:5px;padding-right:10px">
                  <dx-box width="100%">
                    <dxi-item [ratio]="1">
                    </dxi-item>
                    <dxi-item [baseSize]="100" style="position: relative;font-size:5pt">
                      &nbsp;
                      <div class="scoreLabel" style="left:0%">0%</div>
                      <div class="scoreLabel" style="left:25%">25%</div>
                      <div class="scoreLabel" style="left:50%">50%</div>
                      <div class="scoreLabel" style="left:75%">75%</div>
                      <div class="scoreLabel" style="left:100%">100%</div>
                    </dxi-item>
                  </dx-box>
                  <div *ngFor="let item of focusedTan!.objectives">
                    <dx-box width="100%">
                      <dxi-item [ratio]="1">
                        <div class="detailRowText">{{displayObjective(item)}}</div>
                      </dxi-item>
                      <dxi-item [baseSize]="100" style="position: relative;">
                        <div class="detailRowScore">
                          <div class="scoreLine" style="left:25%;"> </div>
                          <div class="scoreLine" style="left:50%;"> </div>
                          <div class="scoreLine" style="left:75%;"> </div>
                          <app-score [score]="item.score"></app-score>
                        </div>
                      </dxi-item>
                    </dx-box>
                  </div>
                  <div style="height:1em"></div>
                  <dx-box width="100%">
                    <dxi-item [ratio]="1">
                      <div style="text-align: right;padding-right:5pt">
                        {{'preparation.tangroup.label.download_detail_report'|translate}}
                      </div>

                    </dxi-item>
                    <dxi-item [baseSize]=100>
                      <dx-button text="{{ focusedTan!.tan +'.pdf' }}" hint="Open Exam"
                        (onClick)="openCandidatePdf(focusedTan!)">
                      </dx-button>
                    </dxi-item>
                  </dx-box>
                </div>
              </ng-template>
            </div>
          </dxi-item>
        </dx-box>
      </dxi-item>
      <dxi-item title="{{'preparation.tangroup.tab.support'|translate}}" class="tabcontainer"
        [visible]="showSupportPage">
        <div>
          <iframe [src]="supportSource! | safe" style="width:100%;height:400px;border:none"></iframe>
        </div>
      </dxi-item>
    </dx-tab-panel>
  </div>
  <div style="background-image:url('/assets/images/bg_right.jpg');width:136px"></div>
</div>

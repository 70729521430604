<div class="dx-viewport">
  <div style="margin:4pt">
    {{id}} {{module}} {{groupName}}
    <span class="screenonly">
      <dx-button icon="print" text="{{ 'preparation.tanoverview.button.print'|translate}}" (onClick)="print()"></dx-button>
    </span>
  </div>
  <div >
    <div style="float:left" class="card" *ngFor="let tan of tans">
      <div class="left">
        <div class="nr">{{tan.nr}}</div>
      </div>
      <div class="right">
        <div class="tan"><span>{{tan.formattedTan}}</span></div>
        <div class="name"><span>{{tan.name}}</span></div>
      </div>
    </div>
  </div>
</div>
<div class="dx-viewport">
  <div class="page-header">
    <div style="text-align: right;padding-right:20px"><img src="/assets/images/logoheader_its.png"></div>
    <div class="reportheader">{{'tangroupreport.header'|translate}}</div>
  </div>
  <table style="width:100%">
    <thead>
      <tr>
        <td>
          <div class="page-header-space"></div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>

          <table>
            <tr>
              <td><b>{{'tangroupreport.testcenter'|translate}}</b></td>
              <td>{{tcKey}} {{tcName}}</td>
            </tr>
            <tr>
              <td><b>{{'tangroupreport.examname'|translate}}</b></td>
              <td>{{name}}</td>
            </tr>
            <tr>
              <td><b>{{'tangroupreport.examdate'|translate}}</b></td>
              <td>{{examDate}}</td>
            </tr>
          </table>

          <div style="margin-top:2em;text-align:left"><span class="module">{{'tangroupreport.module'|translate:info}}</span></div>
          <div>{{'tangroupreport.scoremax'|translate:info}}
            <span *ngIf="hasMastery">{{'tangroupreport.mastery'|translate:info}}</span>
          </div>
          <h3>{{'tangroupreport.section.candidates'|translate}}</h3>
          <table>
            <tr *ngFor="let tan of tans">
              <td style="height:22pt;font-family: Consolas, 'Courier New', Courier, monospace">{{formatTan(tan)}}</td>
              <td>
                <app-score style="width:100px;display:block" [score]="tan.score"></app-score>
              </td>
              <td>{{tan.name}}</td>
              <td style="text-align: right">{{formatPercent(tan.score)}}%</td>
              <td>{{'tangroupreport.score'|translate:tan.score}}</td>
              <td>{{formatStatus(tan)}}</td>
            </tr>
          </table>

          <h3>{{'tangroupreport.section.objectives'|translate}}</h3>
          <table>
            <tr *ngFor="let obj of objectives">
              <td *ngIf="displayObjectiveId" style="min-width:5em">{{obj.key}}</td>
              <td>
                <app-score style="display:block;width:100px" [score]="obj.score"></app-score>
              </td>
              <td style="text-align: right">{{formatPercent(obj.score)}}%</td>
              <td>{{obj.description}}</td>
            </tr>
          </table>

        </td>
      </tr>
    </tbody>
  </table>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AppInitService } from '../app-init.service';
import { dxConfirm, _ } from '../dx_helper';
import * as Types from '../its-organizer-api.types.g';
import { MyOidcAuthService } from '../my-oidc-auth.service';
import { PreparationExamService } from '../preparation-exam.service';
import { ShowableError } from '../ShowableError';
import { addMetaDataFields } from './addMetaDataFields';
import * as LAUNCH from './launch.mutation.g';
import * as TAN from './tan.query.g';
import * as VALIDATE from './validate.mutation.g';

type MODE = 'testcenter' | 'tan' | 'proctor' | 'candidate';
type TanInfo = VALIDATE.IValidateTanMutation['validateTan'];
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public modes: Array<{ id: MODE, display: Observable<any> }> = [];
  public readonly newExamFormItems: any[] = [];
  public newExamVisible = false;

  public newExamFormData: {
    metadata: { [key: string]: string }
  } = {
      metadata: {}
    };


  constructor(
    private route: ActivatedRoute,
    readonly svc: PreparationExamService,
    private initSvc: AppInitService,
    private translate: TranslateService,
    private readonly oidc: MyOidcAuthService,
    private readonly validateSvc: VALIDATE.IValidateTanGQL,
    private readonly launchSvc: LAUNCH.ILaunchTanGQL,
    private readonly tanSvc: TAN.ILogin_TanGQL,
    config: AppConfigService,
  ) {
    const proctorLoginMode = config.getProctorLoginMode();
    this.proctorDirect = proctorLoginMode === 'direct' || proctorLoginMode === 'direct+sso';
    this.proctorSSO = proctorLoginMode === 'sso' || proctorLoginMode === 'direct+sso';
  }

  public mode: MODE = 'tan';
  public proctorDirect: boolean;
  public proctorSSO: boolean;
  public username: string = '';
  public password: string = '';
  public tan: string = '';
  private validatedTan: TanInfo;
  private readonly subscriptions = new Array<Subscription>();
  public instanceName: string | undefined;

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setupFormItems(tanInfo: TanInfo) {
    this.newExamFormItems.splice(0, this.newExamFormItems.length);
    if (!tanInfo) {
      return false;
    }
    addMetaDataFields(this.newExamFormItems, tanInfo && tanInfo.metaData);
    return this.newExamFormItems.length > 0;
  }


  public cancelLaunchTAN() {
    this.newExamVisible = false;
  }

  public async continueLaunchTAN(e: Event) {
    console.log(`continueLaunchTAN`);
    e.preventDefault();
    await this.svc.blockUI({
      name: 'launch tan',
      defaultMsg: _('login.error.login'),
      defaultTitle: _('login.error.login_title'),
      action: async () => {
        await this._continueLaunchTAN();
      }
    });
  }
  private async _continueLaunchTAN() {
    this.newExamVisible = false;
    const metadata = new Array<Types.IMetaDataValueInput>();
    const formMeta = this.newExamFormData.metadata || {};
    if (this.validatedTan && this.validatedTan.metaData) {
      for (const { key } of this.validatedTan.metaData) {
        const value = formMeta[key] || '';
        metadata.push({
          key,
          value
        });
      }
    }

    const launch = await this.svc.networkactivity(() => firstValueFrom(this.launchSvc.mutate({
      tan: this.tan ?? '',
      userAgentString: window.navigator.userAgent,
      metadata
    }, {
      fetchPolicy: 'no-cache',
    })));
    const url = launch && launch.data && launch.data.launchTan;
    if (!url) {
      throw new Error('Unable to get launch url');
    }
    const thisUrl = window.location.href;
    const p = new URL(url);
    console.log(`After exam is completed, user should be redirected to ${thisUrl}`);
    p.searchParams.set('onclose', 'redirect:' + thisUrl);
    console.log(`Redirecting to ${p.href}`);
    this.svc.navigateAway(p.href);
  }

  private async onLoginTAN() {
    if (!this.tan) {
      throw new ShowableError('no tan specified', _('login.error.tan_required'));
    }
    const val = await this.svc.networkactivity(() => firstValueFrom(this.validateSvc.mutate({
      tan: this.tan ?? ''
    })));
    const ok = val?.data?.validateTan;
    if (!ok) {
      throw new ShowableError(`invalid tan code ${this.tan}`, _('login.error.invalid_tan'));
    }
    this.validatedTan = ok;
    for (const field of this.validatedTan.metaData) {
      this.newExamFormData.metadata[field.key] = field.value;
    }
    const info = await this.svc.networkactivity(() => firstValueFrom(this.tanSvc.fetch({})));
    await this.svc.showTanDisclaimer(info.data?.config?.tanDisclaimer?.value);

    if (ok.module.note.value) {
      if (!await dxConfirm(ok.module.note.value, this.translate.instant(_('login.confirm.launch_tan_title')))) {
        return;
      }
    }

    if (this.setupFormItems(ok)) {
      this.newExamVisible = true;
      return;
    }

    await this._continueLaunchTAN();
  }
  private async onLoginTestcenter() {
    await this.svc.logIn(this.username, this.password);
    this.svc.navigate(['preparation-dashboard'], {});
  }
  private async onLoginCandidate() {
    await this.svc.logInCandidate(this.username, this.password);
    this.svc.navigate(['candidate-overview'], {});
  }
  private async onLoginProctor() {
    await this.svc.logIn(this.username, this.password);
    this.svc.navigate(['cert-dashboard'], {});
  }
  public async onLogin() {
    await this.svc.blockUI({
      name: 'login',
      defaultMsg: _('login.error.login'),
      defaultTitle: _('login.error.login_title'),
      action: async () => {
        if (this.mode === 'candidate') {
          await this.onLoginCandidate();
          return;
        }
        if (this.mode === 'proctor') {
          await this.onLoginProctor();
          return;
        }
        if (this.mode === 'testcenter') {
          await this.onLoginTestcenter();
          return;
        }
        if (this.mode === 'tan') {
          await this.onLoginTAN();
          return;
        }
        throw new Error(`invalid mode '${this.mode}'`);
      }
    });
  }

  onFormSubmit = async (e: Event) => {
    e.preventDefault();
    return this.onLogin();
  }

  get showDirectLoginButton() {
    switch (this.mode) {
      case 'proctor':
        return this.proctorDirect;
      default:
        return true;
    }
  }
  get showSSOButton() {
    switch (this.mode) {
      case 'proctor':
        return this.proctorSSO;
      default:
        return false;
    }

  }
  async ngOnInit() {
    this.modes = [];
    const settings = await this.initSvc.getSettings();
    this.instanceName = settings.instanceName;
    if (settings.hasPreparation) {
      this.modes.push({
        id: 'tan',
        display: await firstValueFrom(this.translate.get('login.login_as_tan')),
      });
    }
    if (settings.hasCertification) {
      this.modes.push({
        id: 'candidate',
        display: await firstValueFrom(this.translate.get('login.login_as_candidate')),
      });
    }
    if (settings.hasPreparation) {
      this.modes.push({
        id: 'testcenter',
        display: await firstValueFrom(this.translate.get('login.login_as_testcenter')),
      });
    }
    if (settings.hasCertification && (this.proctorDirect || this.proctorSSO)) {
      this.modes.push({
        id: 'proctor',
        display: await firstValueFrom(this.translate.get('login.login_as_proctor')),
      });
    }
    this.mode = this.modes[0].id;
    this.subscriptions.push(this.route.queryParamMap.subscribe(p => {
      const mode = p.get('mode');
      if (!mode) {
        return;
      }
      switch (mode) {
        case 'tan':
          this.mode = 'tan';
          break;
        case 'candidate':
          this.mode = 'candidate';
          break;
        case 'testcenter':
          this.mode = 'testcenter';
          break;
        case 'proctor':
          this.mode = 'proctor';
          break;
        default:
          console.warn(`Invalid mode in queryArgs: '${mode}'`);
          break;
      }

    }));

  }

  loginSSO() {
    this.oidc.login();
    // this.router.navigate(['/login-oidc-proctor']);
  }
}

<div class="background dx-widget">
  <div class="logoarea">
  </div>
  <div class="logotray">
    <div class="itslogo"></div>
    <div class="bitmedialogo"></div>
  </div>
  <div class="loginform">
    <div class="header">ITS<sup>R3</sup> Organizer<br /></div>
    <div class="section" [innerHTML]="'itsr3redirect.header'|translate"></div>
    <div class="section" [innerHTML]="'itsr3redirect.yes'|translate"></div>
    <div class="explain" [innerHTML]="'itsr3redirect.yes_explain'|translate"></div>
    <div class="section" [innerHTML]="'itsr3redirect.no'|translate"></div>
    <div class="explain" [innerHTML]="'itsr3redirect.no_explain'|translate"></div>

    <!-- <div style="margin-top:2em"><a class="downloadbutton" href="https://go.bitmedia.at/itsr3exe"
        download="itsr3setup">Download</a></div> -->
  </div>
</div>
import { Component, Input, OnInit } from '@angular/core';

interface IPoints {
  gained: number;
  pending: number;
  lost: number;
  max: number;
  mastery?: number | undefined | null;
}
@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css']
})
export class ScoreComponent implements OnInit {

  constructor() { }

  @Input() public score?: IPoints | undefined | null;

  gainedP() {
    if (!this.score) {
      return 0;
    }
    if (!this.score.max) {
      return 0;
    }
    return this.score.gained / this.score.max * 100;
  }
  lostP() {
    if (!this.score) {
      return 0;
    }
    if (!this.score.max) {
      return 0;
    }
    return this.score.lost / this.score.max * 100;
  }
  pendingP() {
    if (!this.score) {
      return 100;
    }
    if (!this.score.max) {
      return 100;
    }
    return this.score.pending / this.score.max * 100;
  }
  hasMastery() {
    if (!this.score) {
      return false;
    }
    return this.score.max && this.score.mastery;
  }
  masteryP() {
    if (!this.score) {
      return false;
    }
    if (!this.score.max) {
      return 0;
    }
    if (!this.score.mastery) {
      return false;
    }
    return this.score.mastery / this.score.max * 100;
  }
  ngOnInit() {
  }

}

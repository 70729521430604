<div style="display:flex;height:100%" class="dx-widget">
  <div style="background-image:url('/assets/images/bg_left.jpg');width:136px"></div>
  <div
    style="flex-grow:1;padding:2em;box-shadow: 0px 0px 35px 5px rgba(0,0,0,0.3);z-index:1;display: flex; flex-direction: column;">
    <app-header itslogoRoute="/login"></app-header>
    <div style="display:flex;width:100%">
      <div style="flex-grow:1">
        <h3 class="dx-theme-accent-as-text-color">{{ 'candidate.overview.title' | translate }}</h3>
      </div>
      <div>
        <app-refresh-button (refresh)="refresh()"></app-refresh-button>
        &nbsp;
        <dx-button text="{{ 'candidate.overview.button.logout' | translate }}" (onClick)="logout()"></dx-button>
      </div>
    </div>
    <div *ngIf="!checkClientOk">
      <app-itsclient-required [minClientVersion]="minClientVersion"></app-itsclient-required>
    </div>
    <div *ngIf="checkClientOk" style="height:calc(100% - 150px)">
      <div class="header">{{ 'candidate.overview.header' | translate}}</div>
      <div class="hint">{{ 'candidate.overview.hint' | translate}}</div>
      <dx-scroll-view [height]="'calc(100% - 100px)'">
        <dx-accordion #accordion [dataSource]="topics" [collapsible]="false" [multiple]="false">
          <div *dxTemplate="let topic of 'title'">
            <h1 class="TopicTitle">{{topic.title}}</h1>
          </div>
          <div *dxTemplate="let topic of 'item'">
            <dx-tile-view [height]="'inherit'" [elementAttr]="{ class: 'Tiles' }" [baseItemHeight]="120"
              [showScrollbar]="'true'" [baseItemWidth]="290" width="100%" [itemMargin]="20" direction="vertical"
              [dataSource]="topic.candidates" itemTemplate="tile" (onItemClick)="clickTile($event)">
              <div [ngClass]="getTileCssClass(candidate)" *dxTemplate="let candidate of 'tile'">
                <div class="ModuleTitle">{{candidate.module.shortName}}</div>
                <div [ngClass]="getDemoCssClass(candidate)" class="Demo">
                  <div><span class="Caption">{{ 'candidate.overview.demostart' | translate }}</span></div>
                  <div><i [ngClass]="getLaunchDemoButtonCssClass(candidate)"></i></div>
                </div>
                <div [ngClass]="getLaunchCssClass(candidate)" class="Launch">
                  <div><span class="Caption">{{ 'candidate.overview.start' | translate }}</span></div>
                  <div>
                    <i [ngClass]="getLaunchButtonCssClass(candidate)"></i>
                  </div>
                </div>
              </div>
            </dx-tile-view>
          </div>
        </dx-accordion>
      </dx-scroll-view>
    </div>
  </div>
  <div style="background-image:url('/assets/images/bg_right.jpg');width:136px"></div>
  <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="examRunningVisible" [showIndicator]="true"
    [showPane]="true" [shading]="true" [closeOnOutsideClick]="false" [message]="examRunningMsg()">
  </dx-load-panel>
</div>
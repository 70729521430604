import { Component, OnInit, Pipe, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DxDataGridComponent } from 'devextreme-angular';
import type DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import { formatDate } from 'devextreme/localization';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AppInitService } from '../app-init.service';
import { AS, dxConfirm, DxFormItem, toolbar_preparing, _ } from '../dx_helper';
import { itemCompletionPercentage } from '../helper';
import * as Types from '../its-organizer-api.types.g';
import { LoginTokenService, PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';
import * as FINISH from './finish.mutation.g';
import * as PRESENT from './present.mutation.g';
import * as REFRESH from './refresh.mutation.g';
import * as RESET from './reset.mutation.g';
import * as UPDATE from './updateexam.mutation.g';

interface DownloadableFile {
  id: 'interim-pdf' | 'pdf' | 'zip';
  text: string;
  filename: string;
  interim: boolean;
}

type Person = Q.ICert_Exam_DataQuery['exam']['persons'][0] & {
  display: string;
};

type Candidate = Q.ICert_Exam_DataQuery['exam']['candidates'][0] & {
  display: string;
  status: Types.CandidateStatus | 'finished';
  moduleDisplay: string;
  moduleGroupDisplay: string;
  topic: string;
  percentComplete: number;
  passedDisplay: string;
  canReset: boolean;
  attempt_display: number;
  timepassed_display: string;
};

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

function formatDuration(isoPeriod: string | undefined | null) {
  if (!isoPeriod) {
    return '';
  }
  const duration = moment.duration(isoPeriod);
  const totalSeconds = duration.asSeconds();
  const hours = Math.floor(totalSeconds / (60 * 60));
  const minutes = Math.floor((totalSeconds / 60) % 60);
  const seconds = Math.floor(totalSeconds % 60);
  function pad0(val: number) {
    if (val < 10) {
      return '0' + val.toString();
    }
    return val.toString();
  }
  const h = hours.toString();
  const mm = pad0(minutes);
  const ss = pad0(seconds);
  return `${h}:${mm}:${ss}`;
}
@Component({
  selector: 'app-cert-exam',
  templateUrl: './cert-exam.component.html',
  styleUrls: ['./cert-exam.component.css']
})
export class CertExamComponent implements OnInit {
  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent> | undefined;

  public showPassed = false;
  public examDate?: string;
  public examName?: string;
  public testcenter?: string;
  public isStandalone?: boolean;
  public hasReset?: boolean;
  public id?: string;
  public presentCount?: number;
  public supportSource?: string;
  public showSupportPage?: boolean;
  public status?: Types.ExamStatus;
  public personsDS: CustomStore;
  private persons: Person[] = [];
  public examId?: string;
  public candidatesDS: CustomStore;
  public files: DownloadableFile[] | null = null;
  public updateFormData: { file?: File } = {};
  public readonly updateFormItems: DxFormItem[] = [];

  public showUpdateDlg = false;
  public finishButton?: DevExpress.ui.dxButton;

  onOverviewToolbarPreparing = toolbar_preparing(e => {
    e.toolbarOptions.items.unshift();
  });
  onProgressToolbarPreparing = toolbar_preparing(e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: AS<DevExpress.ui.dxButton.Properties>({

          disabled: this.status === 'done',
          onInitialized: eInit => this.finishButton = eInit.component,
          text: this.translate.instant(_('cert.exam.button.finish')),
          onClick: this.finishExam.bind(this),
        })
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          text: this.translate.instant(_('cert.exam.button.refresh')),
          onClick: this.refresh.bind(this),
        }
      });
  });

  constructor(
    private readonly tokenSvc: LoginTokenService,
    private readonly svc: PreparationExamService,
    private readonly initSvc: AppInitService,
    private readonly config: AppConfigService,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly qSvc: Q.ICert_Exam_DataGQL,
    private readonly presentSvc: PRESENT.ICert_Exam_PresentGQL,
    private readonly finishSvc: FINISH.ICert_Exam_FinishGQL,
    private readonly updateSvc: UPDATE.INewcertexam_UpdateGQL,
    private readonly refreshSvc: REFRESH.ICert_Exam_RefreshGQL,
    private readonly resetSvc: RESET.ICert_Candidate_ResetGQL,
  ) {
    this.personsDS = new CustomStore({
      key: 'id',
      load: async () => {
        const id = this.id!;
        const q = await firstValueFrom(this.qSvc.fetch(
          {
            id
          }));
        const cand: Person[] = q.data.exam.persons.sort((a, b) => a.reference.localeCompare(b.reference)).map((x, xIdx) => {
          return Object.assign(x, {
            display: `${x.reference} ${x.name}`,
          });
        });
        this.persons.splice(0, this.persons.length, ...cand);
        this.presentCount = cand.filter(x => x.present).length;
        return cand;
      },
      update: async (key, values) => {
        const p = this.persons.find(x => x.id === key);
        if (!p) { throw new Error(); }
        if (typeof values.present !== 'undefined') {
          const r = await firstValueFrom(this.presentSvc.mutate({
            examId: this.id!,
            reference: p.reference,
            present: values.present
          }
          ));
        }
      }
    });
    this.candidatesDS = new CustomStore({
      key: 'id',
      load: async () => {
        const id = this.id!;

        const q = await firstValueFrom(this.qSvc.fetch({
          id
        }));

        const cand: Candidate[] = q.data.exam.candidates
          .filter(x => x.person.present)
          .sort((a, b) => a.reference.localeCompare(b.reference)).map((x, xIdx) => {
            return Object.assign(x, {
              display: `${x.reference} ${x.name}`,
              moduleDisplay: `${x.module.shortName}`,
              moduleGroupDisplay: (x.moduleGroup && x.moduleGroup.shortName && x.moduleGroup.shortName.value) || '',
              topic: x.module.topic || this.translate.instant(_('default_topic')),
              passedDisplay: this.svc.yesNo(x.passed ?? false),
              percentComplete: itemCompletionPercentage(x.progress ?? ''),
              status: this.status === 'done' ? 'finished' : x.status,
              attempt_display: x.status === 'open' && x.attempt === 0 ? 0 : x.attempt + 1,
              canReset: x.status === 'open' ? false : x.module.canBeResetByProctor,
              timepassed_display: formatDuration(x.timePassed),
            });
          });
        this.showPassed = cand.some(x => !!x.passedDisplay);
        this.hasReset = cand.some(x => x.module.canBeResetByProctor);
        return cand;

      },
    },

    );


  }
  reloadTab() {
    return this.requery();
  }
  async finishExam() {
    if (!await dxConfirm(
      this.translate.instant(_('cert.exam.confirm.finish')),
      this.translate.instant(_('cert.exam.confirm.finish_title')))) {
      return;
    }
    await firstValueFrom(this.finishSvc.mutate({
      examId: this.id!
    }));
    return this.requery();
  }
  goBack() {
    return this.svc.navigate(['cert-dashboard'], {});
  }
  hasFile(data: Candidate) {
    console.dir(data);
    return data.status === Types.CandidateStatus.Completed;
  }
  hasDetailedFile(data: Candidate) {
    return (data.status === Types.CandidateStatus.Completed) && data.module.includeDetailsPdf;
  }
  fileUploaderValueChanged(e: { value: File[] }) {
    this.updateFormData.file = e.value && e.value[0];
  }

  async showUpdateExam() {
    this.showUpdateDlg = true;
  }
  async updateXlsx(e: Event) {
    e.preventDefault();
    await this.svc.blockUI({
      action: async () => {
        console.log(this.updateFormData);
        const result = await this.svc.networkactivity(() => firstValueFrom(this.updateSvc.mutate({
          examId: this.examId!,
          xlsxFile: this.updateFormData.file,
        }, {
          context: {
            useMultipart: true
          }
        }
        )));
        await this.refresh();
        this.showUpdateDlg = false;
      }
    });

    this.showUpdateDlg = false;
  }
  async saveExamXlsx() {
    const examId = encodeURIComponent(this.examId!);
    const access_token = encodeURIComponent(this.tokenSvc.token!);
    const url = this.config.getApiUrl() + `exam/${examId}/data.xlsx?access_token=${access_token}`;
    console.log(url);
    window.open(url);
  }
  async downloadFile(data: Candidate) {
    const tanId = encodeURIComponent(data.id);
    const access_token = encodeURIComponent(this.tokenSvc.token!);
    const url = this.config.getApiUrl() + `candidate/${tanId}/report.pdf?access_token=${access_token}`;
    console.log(url);
    window.open(url);
  }
  async downloadDetailedFile(data: Candidate) {
    const tanId = encodeURIComponent(data.id);
    const access_token = encodeURIComponent(this.tokenSvc.token!);
    const url = this.config.getApiUrl() + `candidate/${tanId}/report-detailed.pdf?access_token=${access_token}`;
    console.log(url);
    window.open(url);
  }
  async requery() {
    console.log('requery');
    const q = await firstValueFrom(this.qSvc.fetch({
      id: this.id!
    }));
    console.dir(q.data);
    this.examId = q.data.exam.key;
    this.testcenter = q.data.exam.testcenter.name;
    this.examDate = formatDate(new Date(q.data.exam.date), 'longdate');
    this.examName = q.data.exam.name;
    this.status = q.data.exam.status;
    this.isStandalone = q.data.exam.isStandalone;
    this.files = [];
    if (q.data.config.interimReportEnabled && this.status !== 'done') {
      this.files.push({
        id: 'interim-pdf',
        text: this.translate.instant(_('cert.exam.label.interimExamReport')),
        filename: this.examId + '-interim.pdf',
        interim: true,
      });
    }
    this.files.push({
      id: 'pdf',
      text: this.translate.instant(_('cert.exam.label.examReport')),
      filename: this.examId + '.pdf',
      interim: false,
    });
    this.files.push({
      id: 'zip',
      text: this.translate.instant(_('cert.exam.label.allReportsAsZip')),
      filename: this.examId + '.zip',
      interim: false,
    });
    if (this.finishButton) {
      const disabled = this.status === 'done';
      console.log(`update finish button. Disabled=${disabled}, ${this.status}`);
      this.finishButton.option(AS<DevExpress.ui.dxButton.Properties>({
        disabled
      }));
    }
    if (this.dataGrids) {
      await Promise.all(this.dataGrids.map(async x => {
        try {
          x.instance.refresh();
        } catch (e) {
          if (e instanceof Error) {
            console.error(e.message);
          }
        }
      }));
    }
  }
  hasResultFile(file: DownloadableFile) {
    if (file.interim) {
      return true;
    }
    return this.status === Types.ExamStatus.Done;
  }
  openResultFile(file: DownloadableFile) {
    if (!this.hasResultFile(file)) {
      return;
    }
    switch (file.id) {
      case 'interim-pdf': {
        const tanId = encodeURIComponent(this.id!);
        const access_token = encodeURIComponent(this.tokenSvc.token!);
        const url = this.config.getApiUrl() + `exam/${tanId}/interim-report.pdf?access_token=${access_token}`;
        window.open(url);
        break;
      }
      case 'pdf': {
        const tanId = encodeURIComponent(this.id!);
        const access_token = encodeURIComponent(this.tokenSvc.token!);
        const url = this.config.getApiUrl() + `exam/${tanId}/report.pdf?access_token=${access_token}`;
        window.open(url);
        break;
      }
      case 'zip': {
        const tanId = encodeURIComponent(this.id!);
        const access_token = encodeURIComponent(this.tokenSvc.token!);
        const url = this.config.getApiUrl() + `exam/${tanId}/reports.zip?access_token=${access_token}`;
        window.open(url);
        break;
      }
    }
  }
  async refresh() {
    await firstValueFrom(this.refreshSvc.mutate({
      examId: this.id!
    }));
    return this.requery();
  }

  public isResetCandidateVisible(e: {
    component?: DevExpress.ui.dxDataGrid<Candidate, string>;
    row?: DevExpress.ui.dxDataGrid.Row<Candidate, string>;
    column?: DevExpress.ui.dxDataGrid.Column<Candidate, string>;
  }) {
    console.dir(e);
    if (!e.row || !e.row.data || !e.row.data.module) {
      return false;
    }
    return e.row.data.module.canBeResetByProctor;
  }

  public isResetCandidateDisabled(e: {
    component?: DevExpress.ui.dxDataGrid<Candidate, string>;
    row?: DevExpress.ui.dxDataGrid.Row<Candidate, string>;
    column?: DevExpress.ui.dxDataGrid.Column<Candidate, string>;
  }) {
    console.dir(e);
    if (!e.row || !e.row.data || !e.row.data.module) {
      return false;
    }
    return !e.row.data.canReset;
  }

  public readonly resetCandidate = async (e: DevExpress.ui.dxDataGrid.ColumnButtonClickEvent<Candidate>) => {
    if (!e.row) {
      return;
    }
    if (!await dxConfirm(
      this.translate.instant(_('cert.exam.confirm.reset')),
      this.translate.instant(_('cert.exam.confirm.reset_title')))) {
      return;
    }
    const candidateId = e.row.data.id;
    console.log(`Resetting ${candidateId}`);
    await firstValueFrom(this.resetSvc.mutate({
      candidateId,
    }));
    return this.requery();
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      throw new Error('id parameter missing');
    }
    this.id = id;
    const config = await this.initSvc.getSettings();
    this.supportSource = config.supportIFrameUrl ?? undefined;
    this.showSupportPage = !!this.supportSource;

    await this.refresh();
    this.updateFormItems.push(AS<DevExpress.ui.dxForm.SimpleItem>({
      itemType: 'simple',
      label: {
        location: 'top',
        text: this.translate.instant(_('cert.exam.updatedlg.field.file'))
      },

      template: 'fileTemplate',
      validationRules: [{ type: 'required' }],
    }));
  }

}

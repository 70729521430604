import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { formatDate } from 'devextreme/localization';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { AppInitService } from '../app-init.service';
import { filterUndefined } from '../helper';
import { PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';

type TAN = Q.ITanreport_DataQuery['tanGroup']['tan'];
type Objective = NonNullable<TAN['objectives']>[0];
type Section = NonNullable<TAN['sections']>[0];
type Item = Section['items'][0];

interface MetaData {
  label: string;
  value: string;
}

@Component({
  selector: 'app-tan-report',
  templateUrl: './tan-report.component.html',
  styleUrls: ['./tan-report.component.css']
})
export class TanReportComponent implements OnInit {
  public tangroup_id: string;
  public tan_id: string;

  public tan?: string;
  public tanName?: string;
  public tcKey?: string;
  public tcName?: string;
  public hasMastery?: boolean;
  public tgName?: string;
  public module?: string;
  public score: TAN['score'];
  public objectives?: Objective[];
  public items?: Item[];
  public examDate?: string;
  public grade?: string;
  public metadata?: MetaData[];
  public displayObjectiveId?: boolean;
  public info: {
    mastery?: number;
  } = {};

  formatPercent(score: TAN['score']) {
    if (!score) {
      return '';
    }
    if (!score.max) {
      return '';
    }
    return Math.round(100 * score.gained / score.max).toFixed(2);
  }
  constructor(
    readonly svc: PreparationExamService,
    private readonly qSvc: Q.ITanreport_DataGQL,
    private initSvc: AppInitService,
    readonly activatedRoute: ActivatedRoute
  ) {
    const tanGroupId = activatedRoute.snapshot.paramMap.get('id');
    const tanId = activatedRoute.snapshot.paramMap.get('tanid');
    if (!tanGroupId) {
      throw new Error('no tanGroupId');
    }
    if (!tanId) {
      throw new Error('no tanId');
    }
    this.tangroup_id = tanGroupId;
    this.tan_id = tanId;
  }

  async ngOnInit() {
    const settings = await this.initSvc.getSettings();
    const r = await firstValueFrom(this.qSvc.fetch({
      id: this.tangroup_id,
      tan: this.tan_id,
      password: this.svc.getTanGroupPassword(this.tangroup_id)
    }));
    const tg = r.data.tanGroup;

    this.displayObjectiveId = !settings.hideObjectiveId;

    this.examDate = tg.tan.startTime && formatDate(moment(tg.tan.startTime).toDate(), { type: 'longDate' }) || undefined;
    this.tgName = tg.name;
    this.objectives = (tg.tan.objectives || []).sort((a, b) => a.key.localeCompare(b.key));
    const items: Item[] = [];
    for (const section of (tg.tan.sections || [])) {
      items.push(...section.items || []);
    }
    this.items = items;
    this.tcKey = tg.testcenter.testcenterId;
    this.tcName = tg.testcenter.name;
    this.tan = tg.tan.tan;
    this.module = tg.module.shortName;
    this.grade = tg.tan.grade ?? '';
    this.score = tg.tan.score;
    this.info.mastery = this.score?.max && this.score?.mastery ? this.score?.mastery : undefined;
    this.hasMastery = !!this.info.mastery;
    this.tanName = tg.tan.name ?? '';
    this.metadata = filterUndefined([...tg.metadata, ...tg.tan.metadata].map(x => {
      if (!x) {
        return undefined;
      }
      switch (x.__typename) {
        case 'MetaDataTextField':
          return {
            label: x.label,
            value: x.value
          };
        case 'MetaDataComboField':
          const v = x.options.find(y => y.value === x.value);
          return {
            label: x.label,
            value: v && v.label || x.value,
          };
        default:
          return undefined;
      }
    }));
  }

}

import { HttpHeaders } from '@angular/common/http';
import { ApolloClientOptions, ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';

import { ExtractFiles } from 'apollo-angular/http/types';
import { AppConfigService } from './app-config.service';
import { LoginTokenService } from './preparation-exam.service';

// @ts-ignore
import extractFilesMJS from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFileMJS from 'extract-files/isExtractableFile.mjs';

const extractFiles: ExtractFiles = (body) => {
    const retVal = extractFilesMJS(body, isExtractableFileMJS);
    return retVal;
};


export function createApollo(
    this: void,
    httpLink: HttpLink,
    svc: LoginTokenService,
    config: AppConfigService,
): Record<string, ApolloClientOptions<any>> {
    const middleWare = new ApolloLink((operation, forward) => {
        if (svc.token) {
            operation.setContext({
                headers: new HttpHeaders().set('Authorization', 'Bearer ' + svc.token)
            });
        }
        return forward(operation);
    });

    const link = ApolloLink.from([middleWare,
        httpLink.create({
            uri: config.getGraphQLUrl(),
            extractFiles,
        })]);
    const defaultOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    };

    return {
        'itsorganizerapi': {
            link,
            cache: new InMemoryCache(),
            defaultOptions: defaultOptions,
        }
    };
}



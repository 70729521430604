import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { formatDate, formatNumber } from 'devextreme/localization';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';

type Objective = NonNullable<Q.ICandidate_Report_DataQuery['candidate']['objectives']>[0] & {
  depthClass: string;
};
type Section = NonNullable<Q.ICandidate_Report_DataQuery['candidate']['sections']>[0];
type Item = Section['items'][0];
type Score = Q.ICandidate_Report_DataQuery['candidate']['score'];

@Component({
  selector: 'app-candidate-report',
  templateUrl: './candidate-report.component.html',
  styleUrls: ['./candidate-report.component.css']
})
export class CandidateReportComponent implements OnInit {
  private id: string;
  public reference?: string;
  public candidateName?: string;
  public examDate?: string;
  public objectives?: Objective[];
  public items?: Item[];
  public score: Score;
  public module?: string;
  public hasMastery?: boolean;
  public tcKey?: string;
  public mastery?: number;
  public tcName?: string;
  public grade?: string;

  constructor(
    private readonly svc: PreparationExamService,
    private readonly qSvc: Q.ICandidate_Report_DataGQL,
    private readonly activatedRoute: ActivatedRoute
  ) {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!id) {
      throw new Error('param id missing');
    }
    this.id = id;
  }

  formatItem(item: Item) {
    return item.itemId.split('!')[0];
  }
  formatNumber(val: number | undefined | null) {
    if (typeof val !== 'number') {
      return '';
    }
    return formatNumber(val, { precision: 2 });
  }
  formatScore(score: Score) {
    if (!score) {
      return '';
    }
    if (!score.max) {
      return '';
    }
    const gained = this.formatNumber(score.gained);
    const max = this.formatNumber(score.max);
    return `${gained}/${max}`;
  }
  formatPercent(score: Score) {
    if (!score) {
      return '';
    }
    if (!score.max) {
      return '';
    }
    return formatNumber(Math.round(100 * score.gained / score.max), {
      type: 'fixedPoint',
      precision: 0
    }) + '%';
  }

  async ngOnInit() {
    const r = await firstValueFrom(this.qSvc.fetch({
      id: this.id,
    }));
    const tg = r.data.candidate;
    this.examDate = tg.startTime && formatDate(moment(tg.startTime).toDate(), { type: 'longDate' }) || undefined;
    this.objectives = (tg.objectives || [])
      .map(x => ({ ...x, depthClass: 'obj-leaf' }))
      .sort((a, b) => a.key.localeCompare(b.key));
    const rootIds = this.objectives.filter(x => x.key.split('.').length === 1).map(x => x.key);
    if (rootIds.length === 1) {
      const remove = rootIds[0];
      const replace = remove + '.';
      for (const obj of this.objectives) {
        if (obj.key === remove) {
          obj.key = '';
        } else if (obj.key.startsWith(replace)) {
          obj.key = obj.key.substr(replace.length);
        }
      }
    }
    this.objectives = this.objectives.filter(x => !!x.key);
    for (const obj of this.objectives) {
      const depth = obj.key.split('.').length;
      obj.depthClass = `objective-${depth}`;
    }
    const items: Item[] = [];
    for (const section of (tg.sections || [])) {
      items.push(...section.items || []);
    }
    this.items = items;
    this.tcKey = tg.exam.testcenter.testcenterId;
    this.tcName = tg.exam.testcenter.name;
    this.reference = tg.reference;
    this.module = tg.module.shortName;
    this.score = tg.score;
    this.grade = tg.grade ?? '';
    this.hasMastery = !!(this.score && this.score.mastery && this.score.max);
    this.mastery = this.hasMastery && Math.ceil(this.score!.mastery!) || undefined;
    this.candidateName = tg.name;
  }

}

<div style="display:flex;height:100%" class="dx-widget">
  <div style="background-image:url('/assets/images/bg_left.jpg');width:136px"></div>
  <div style="flex:1;overflow:auto;padding:2em;box-shadow: 0px 0px 35px 5px rgba(0,0,0,0.3);z-index:1">
    <app-header itslogoRoute=":back"></app-header>
    <div style="display:flex;width:100%">
      <div style="flex-grow:1">
        <h3 class="dx-theme-accent-as-text-color">{{ 'cert.exam.title' | translate }}</h3>
      </div>
      <div>
        <app-refresh-button (refresh)="refresh()"></app-refresh-button>
        &nbsp;
        <span *ngIf="isStandalone">
          <dx-button icon="fa fa-upload" text="{{'cert.exam.button.uploadxlsx'|translate}}"
            (onClick)="showUpdateExam()"></dx-button>
          &nbsp;
          <dx-button icon="fa fa-download " text="{{'cert.exam.button.downloadxlsx'|translate}}"
            (onClick)="saveExamXlsx()"></dx-button>
          &nbsp;
        </span>
        <dx-button text="{{'cert.exam.button.back'|translate}}" (onClick)="goBack()"></dx-button>

      </div>
    </div>
    <table class="exam_details">
      <tr>
        <td class="label">{{'cert.exam.label.examid'|translate}}:</td>
        <td>{{examId}}</td>
        <td class="label">{{'cert.exam.label.examdate'|translate}}:</td>
        <td>{{examDate}}</td>
        <td class="label">{{'cert.exam.label.name'|translate}}:</td>
        <td>{{examName}}</td>
      </tr>
      <tr>
        <td class="label">{{'cert.exam.label.personsPresent'|translate}}:</td>
        <td>{{presentCount}}</td>
        <td class="label">{{'cert.exam.label.testcenter'|translate}}:</td>
        <td>{{testcenter}}</td>
      </tr>
    </table>
    <dx-tab-panel #tabPanel (onSelectionChanged)="reloadTab()">
      <dxi-item title="{{'cert.exam.tab.overview'|translate}}" class="tabcontainer">
        <dx-data-grid #dataGridOverview keyExpr="id" [dataSource]="personsDS" [focusedRowEnabled]="true"
          (onToolbarPreparing)="onOverviewToolbarPreparing($event)" [allowColumnResizing]="true"
          [columnAutoWidth]="true" [hoverStateEnabled]="true" style="width:100%">

          <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
          <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10,20,50,'all']"></dxo-pager>
          <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="false">
          </dxo-editing>
          <dxi-column caption="{{'cert.exam.grid.present'|translate}}" dataField="present" dataType="boolean" width="70"
            [allowEditing]="true">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.candidates'|translate}}" dataField="display" width="300"
            [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.loggedin'|translate}}" dataField="loggedIn" dataType="boolean"
            width="100" [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.examstarted'|translate}}" dataField="anyExamStarted" dataType="boolean"
            width="120" [allowEditing]="false">
          </dxi-column>
          <dxi-column></dxi-column>
        </dx-data-grid>
      </dxi-item>
      <dxi-item title="{{'cert.exam.tab.progress'|translate}}" class="tabcontainer">
        <dx-data-grid style="width:100%" keyExpr="id" [dataSource]="candidatesDS" [focusedRowEnabled]="true"
          #dataGridProgress (onToolbarPreparing)="onProgressToolbarPreparing($event)" [hoverStateEnabled]="true"
          [columnAutoWidth]="true" [allowColumnResizing]="true">
          <dxo-grouping [contextMenuEnabled]="true">
          </dxo-grouping>
          <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
          <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10,20,50,'all']"></dxo-pager>
          <dxo-group-panel [visible]="true" [allowColumnDragging]="false">
          </dxo-group-panel>
          <dxo-export [enabled]="false"></dxo-export>
          <dxi-column caption="{{'cert.exam.grid.candidates'|translate}}" dataField="display" width="250"
            [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.modulegroup'|translate}}" dataField="moduleGroupDisplay" width="100"
            [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.topic'|translate}}" dataField="topic" width="100"
            [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.module'|translate}}" dataField="moduleDisplay" width="150"
            [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.progress'|translate}}" dataField="progress" width="130"
            [allowEditing]="false" cellTemplate="progress">
          </dxi-column>
          <dxi-column [(visible)]="showPassed" caption="{{'cert.exam.grid.passed'|translate}}" dataField="passedDisplay"
            width="100" [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.attempt'|translate}}" dataField="attempt_display" width="100"
            [allowEditing]="false" [visible]="hasReset">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.timepassed'|translate}}" dataField="timepassed_display" width="100"
            alignment="right" [allowEditing]="false">
          </dxi-column>
          <dxi-column caption="{{'cert.exam.grid.results'|translate}}" cellTemplate="results" width="200"
            [allowEditing]="false" [allowGrouping]="false">
          </dxi-column>
          <dxi-column type="buttons" alignment="right">
            <dxi-button text="{{'cert.exam.grid.reset.text'|translate}}"
              hint="{{'cert.exam.grid.reset.hint'|translate}}" [onClick]="resetCandidate"
              [visible]="isResetCandidateVisible" [disabled]='isResetCandidateDisabled'></dxi-button>
          </dxi-column>
          <div *dxTemplate="let cell of 'results'">
            <span *ngIf="hasFile(cell.data)">
              <dx-button text="{{cell.data.reference}}-{{cell.data.module.moduleId}}.pdf"
                (onClick)="downloadFile(cell.data)"></dx-button>
            </span>
            <span *ngIf="hasDetailedFile(cell.data)" style="padding-left:5px;">
              <dx-button text="{{cell.data.reference}}-{{cell.data.module.moduleId}}-detailed.pdf"
                (onClick)="downloadDetailedFile(cell.data)"></dx-button>
            </span>
          </div>
          <div *dxTemplate="let cell of 'progress'">
            <div *ngIf="cell.data.status === 'open'">
              {{'cert.exam.message.open'|translate}}
            </div>
            <div *ngIf="cell.data.status === 'inprogress'">
              <app-percentagebar [percentage]="cell.data.percentComplete"></app-percentagebar>
            </div>
            <div *ngIf="cell.data.status === 'completed'">
              {{'cert.exam.message.completed'|translate}}
            </div>
            <div *ngIf="cell.data.status === 'finished'">
              {{'cert.exam.message.finished'|translate}}
            </div>
          </div>
        </dx-data-grid>
        <dx-data-grid [dataSource]="files" [allowColumnResizing]="true">

          <dxi-column caption="{{ 'cert.exam.grid.result' | translate}}" dataField="text" width="130">
          </dxi-column>
          <dxi-column caption="{{ 'cert.exam.grid.file' | translate}}" cellTemplate="file"></dxi-column>
          <div *dxTemplate="let cell of 'file'">
            <div *ngIf="hasResultFile(cell.data)">
              <dx-button text="{{cell.data.filename}}" (onClick)="openResultFile(cell.data)"></dx-button>
            </div>
          </div>
        </dx-data-grid>
      </dxi-item>
      <dxi-item title="{{'cert.exam.tab.support'|translate}}" class="tabcontainer" [visible]="showSupportPage">
        <iframe *ngIf="supportSource" [src]="supportSource | safe" style="width:100%;height:400px;border:none"></iframe>
      </dxi-item>
    </dx-tab-panel>
  </div>

  <div style="background-image:url('/assets/images/bg_right.jpg');width:136px"></div>

  <dx-popup [(visible)]="showUpdateDlg" title="{{'cert.exam.updatedlg.title'|translate}}" [showTitle]="true"
    [width]="500" [height]="'auto'">
    <form (submit)="updateXlsx($event)">
      <dx-form [(formData)]="updateFormData" [items]="updateFormItems" validationGroup="newExamForm">
        <div *dxTemplate="let data of 'fileTemplate'">
          <dx-file-uploader #uploader name="file" uploadMode="useForm"
            (onValueChanged)="fileUploaderValueChanged($event)"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></dx-file-uploader>
        </div>
      </dx-form>
      <div style="padding-top:2em">
        <dx-button type="default" [useSubmitBehavior]="true" validationGroup="newExamForm"
          text="{{'cert.exam.updatedlg.button.import'|translate}}">
        </dx-button>
      </div>
    </form>

  </dx-popup>

</div>
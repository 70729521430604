<div class="background dx-widget">
    <div class="logoarea">
    </div>
    <div class="logotray">
        <div class="itslogo"></div>
        <div class="bitmedialogo"></div>
    </div>
    <div class="loginform" id="loginform">
        <div class="header">
            <span class="productName" [innerHTML]="'login.product_name' | translate"></span> <br />
            <span class="instanceName" *ngIf="instanceName">{{instanceName}}</span>
            <span class="mode" *ngIf="mode=='testcenter' || mode == 'tan'"><br />{{'login.preparation_title' |
        translate}}</span>
            <span class="mode" *ngIf="mode=='proctor' || mode == 'candidate'"><br />{{'login.certification_title' |
        translate}}</span>
        </div>

        <form action="#" (submit)="onFormSubmit($event)">
            <div class="dx-field" style="width:200pt">
                <div class="dx-field-label">{{'login.login_as' | translate}}</div>
                <div class="dx-field-value">
                    <dx-select-box [dataSource]="modes" valueExpr="id" displayExpr="display" [(value)]="mode">
                    </dx-select-box>
                </div>
            </div>
            <div *ngIf="mode=='testcenter'">
                <div class="caption dx-theme-accent-as-text-color">{{ 'login.header_testcenter' | translate }}</div>
                <div *ngIf="showDirectLoginButton">
                    <dx-text-box id="username" placeholder="{{ 'login.placeholder.username' | translate }}" [(value)]="username" stylingMode="underlined"></dx-text-box>
                    <dx-text-box id="password" placeholder="{{ 'login.placeholder.password' | translate }}" [(value)]="password" stylingMode="underlined" mode="password">
                    </dx-text-box>
                </div>
            </div>
            <div *ngIf="mode=='proctor'">
                <div class="caption dx-theme-accent-as-text-color">{{ 'login.header_testcenter' | translate }}</div>
                <div *ngIf="showDirectLoginButton">
                    <dx-text-box id="username" placeholder="{{ 'login.placeholder.username' | translate }}" [(value)]="username" stylingMode="underlined"></dx-text-box>
                    <dx-text-box id="password" placeholder="{{ 'login.placeholder.password' | translate }}" [(value)]="password" stylingMode="underlined" mode="password">
                    </dx-text-box>
                </div>
            </div>
            <div *ngIf="mode=='candidate'">
                <div class="caption dx-theme-accent-as-text-color">{{ 'login.header_testcenter' | translate }}</div>
                <div *ngIf="showDirectLoginButton">
                    <dx-text-box id="username" placeholder="{{ 'login.placeholder.examid' | translate }}" [(value)]="username" stylingMode="underlined"></dx-text-box>
                    <dx-text-box id="password" placeholder="{{ 'login.placeholder.name' | translate }}" [(value)]="password" stylingMode="underlined">
                    </dx-text-box>
                </div>
            </div>
            <div *ngIf="mode=='tan'">
                <div class="caption dx-theme-accent-as-text-color">{{ 'login.header_tan' | translate }}</div>
                <div *ngIf="showDirectLoginButton">
                    <dx-text-box style="font-size:30pt" id="tan" placeholder="TAN" [(value)]="tan" mask="AA   AA   AA   AA" stylingMode="underlined"></dx-text-box>
                </div>
            </div>
            <div class="loginbuttons">
                <div *ngIf="showDirectLoginButton">
                    <dx-button id="loginbutton" type="default" [useSubmitBehavior]="true">
                        <div *dxTemplate="let data of 'content'">
                            <span class='dx-button-text'>{{ 'login.button.login' | translate}}</span>
                        </div>
                    </dx-button>
                </div>
                <div *ngIf="showSSOButton">
                    <dx-button id="loginssobutton" type="default" (click)="loginSSO()">
                        <div *dxTemplate="let data of 'content'">
                            <span class='dx-button-text'>{{ 'login.button.sso' | translate}}</span>
                        </div>
                    </dx-button>

                </div>
            </div>
        </form>
        <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#loginform' }" [visible]="svc.uiBlocked" [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
        </dx-load-panel>
        <dx-popup [(visible)]="newExamVisible" [showTitle]="true" [width]="300" [height]="'auto'" title="{{'login.metadata.title' | translate}}" [closeOnOutsideClick]="true">
            <div *dxTemplate="let data of 'content'">
                <form (submit)="continueLaunchTAN($event)">
                    <dx-form id="newExamForm" [(formData)]="newExamFormData" validationGroup="newExamFormVG" [items]="newExamFormItems">
                    </dx-form>
                    <div style="padding-top:2em">
                        <dx-button type="default" text="{{'login.metadata.button.ok' | translate}}" [useSubmitBehavior]="true" validationGroup="newExamFormVG">
                        </dx-button>
                        <dx-button text="{{'login.metadata.button.cancel' | translate}}" (click)="cancelLaunchTAN()">
                        </dx-button>
                    </div>
                </form>
            </div>
        </dx-popup>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';


import { dxAlert, _ } from './dx_helper';
import * as LOGIN from './login.mutation.g';
import * as LOGINCANDIDATE from './loginCandidate.mutation.g';
import * as LOGOUT from './logout.mutation.g';
import { MyOidcAuthService } from './my-oidc-auth.service';
import { isShowableError, ShowableError } from './ShowableError';
import * as VALIDATE from './validateTanGroupPassword.query.g';

const SEP_LEFT = '‹';
const SEP_RIGHT = '›';
const PARSE_ERR = /([.0-9A-Z_]+)‹([^›]*)›/;

@Injectable({
  providedIn: 'root'
})
export class LoginTokenService {
  constructor(
    private readonly oidcAuth: MyOidcAuthService,
  ) {
  }
  private _token: string | undefined;
  private _use_oidc_token: boolean = false;

  public get token() {
    if (this._use_oidc_token) {
      const oidcToken = this.oidcAuth.accessToken;
      console.log(`Using access Token '${oidcToken}'`);
      return oidcToken;
    }
    return this._token;
  }
  public useToken(token: string | undefined) {
    this._token = token;
  }
  public useOidc() {
    console.log('Setting bearer token to OIDC access token');
    this._use_oidc_token = true;
    console.log(this.token);
  }
  public get hasToken() {
    return !!this.token;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PreparationExamService {

  constructor(
    private readonly validateReq: VALIDATE.IValidateTanGroupPasswordGQL,
    private readonly logoutReq: LOGOUT.ILogoutGQL,
    private readonly loginReq: LOGIN.ILoginGQL,
    private readonly loginCandidateReq: LOGINCANDIDATE.ILoginCandidateGQL,
    private readonly router: Router,
    private readonly tokenSvc: LoginTokenService,
    private readonly translate: TranslateService,
  ) {
    console.log(window.location.href);
    const p = new URLSearchParams(window.location.search);
    const token = p.get('access_token');
    console.log(token);
    if (token) {
      this.tokenSvc.useToken(token);
    }
    const itsr3client = p.get('itsr3client');
    if (itsr3client) {
      this.itsr3client = itsr3client;
    }
  }

  public itsr3client: string | undefined;
  public get isLoggedIn(): boolean {
    return this.tokenSvc.hasToken;
  }

  private readonly passwords = new Map<string, string>();
  private blockCounter = 0;
  public uiBlocked = false;

  public tanDisclaimerShown = false;
  public certDisclaimerShown = false;

  public tryLoginOidc() {
    if (!this.tokenSvc.hasToken) {
      this.tokenSvc.useOidc();
    }
  }
  public async showTanDisclaimer(text: string | undefined) {
    if (this.tanDisclaimerShown) {
      return;
    }
    if (text) {
      await dxAlert(text, 'Disclaimer');
    }
    this.tanDisclaimerShown = true;
  }

  public async showCertDisclaimer(text: string | undefined | null) {
    if (this.certDisclaimerShown) {
      return;
    }
    if (text) {
      await dxAlert(text, 'Disclaimer');
    }
    this.certDisclaimerShown = true;
  }

  public async showError({ err, defaultMsg, defaultTitle }: { err: Error, defaultMsg?: string, defaultTitle?: string }) {
    console.log(err);
    if (isShowableError(err)) {
      const message = err.displayMessage || defaultMsg || _('common.errordlg_msg');
      const title = err.displayTitle || defaultTitle || _('common.errordlg_title');
      await this.alert(message, title, err.interpolateParams);
    } else {
      const message = defaultMsg || _('common.errordlg_msg');
      const title = defaultTitle || _('common.errordlg_title');
      await this.alert(message, title);
    }
  }


  public async navigateAway(newUrl: string) {
    console.log(`Blocking UI permanently because navigating to ${newUrl} is imminent`);
    this.blockCounter++;
    this.uiBlocked = true;
    window.location.href = newUrl;
  }

  public async blockUI<T>({ name, action, defaultMsg, defaultTitle }: { name?: string, action: () => Promise<T>, defaultMsg?: string, defaultTitle?: string }) {
    if (name) {
      if (this.blockCounter > 0) {
        console.log(`UI-Action ${name}: BLOCKED`);
        return;
      }
      console.log(`Invoking UI-Action ${name}`);
    }
    try {
      this.blockCounter++;
      this.uiBlocked = this.blockCounter > 0;
      await action();
    } catch (e) {
      if (e instanceof Error) {
        this.showError({
          err: e,
          defaultMsg: defaultMsg,
          defaultTitle: defaultTitle
        });
      }
    } finally {
      this.blockCounter--;
      this.uiBlocked = this.blockCounter > 0;
      if (name) {
        console.log(`Done with ${name}`);
      }
    }
  }

  public async alert<X extends Object>(textId: string, textTitle: string, interpolateParams?: X) {
    await dxAlert(
      this.translate.instant(textId, interpolateParams).replace(/\n/g, '<br>'),
      this.translate.instant(textTitle, interpolateParams));
  }
  public yesNo(val: boolean) {
    if (typeof val !== 'boolean') {
      return '';
    }
    if (val) {
      return this.translate.instant(_('common.yes'));
    } else {
      return this.translate.instant(_('common.no'));
    }
  }
  public navigate(path: string[], queryParams: { [key: string]: string }) {
    return this.router.navigate(path, {
      queryParams: this.queryParams(queryParams)
    });
  }

  public queryParams(values: { [key: string]: string }) {
    if (!values) {
      values = {};
    }
    const token = this.tokenSvc.token;
    if (token) {
      values['access_token'] = token;
    }
    if (this.itsr3client) {
      values['itsr3client'] = this.itsr3client;
    }
    return values;
  }
  public setTanGroupPassword(tanGroupId: string, password: string) {
    this.passwords.set(tanGroupId, password);
  }

  public getTanGroupPassword(tanGroupId: string) {
    return this.passwords.get(tanGroupId) || '';
  }

  public async validateTanGroupPassword(tanGroupId: string, password: string) {
    console.log(`validateTanGroupPassword(${tanGroupId}, ${password})`);

    const d = await firstValueFrom(this.validateReq.fetch({
      id: tanGroupId,
      password
    }));
    const isOk = d.data && d.data.tanGroup && d.data.tanGroup.validatePassword;
    if (!isOk) {
      console.log(`invalid password`);
      return false;
    }
    console.log(`password is good`);
    this.setTanGroupPassword(tanGroupId, password);
    return true;
  }

  public async logout() {
    if (this.isLoggedIn) {
      await this.blockUI({
        name: 'logout',
        action: async () => {
          await this.networkactivity(() => firstValueFrom(this.logoutReq.mutate({})));
          this.tokenSvc.useToken(undefined);
        }
      });
    }
    await this.navigate(['/login'], {});
  }

  public async networkactivity<T>(action: () => Promise<FetchResult<T>>): Promise<FetchResult<T>> {
    let fullMessage = '';
    try {
      const result = await action();
      if (!result.errors) {
        return result;
      }
      fullMessage = result.errors.map(x => x.message).join('\n');
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        fullMessage = e.message;
      }
    }

    const match = fullMessage.match(PARSE_ERR);
    console.dir({
      errormessage: fullMessage,
      match
    });
    if (match) {
      const id = match[1];
      const message = match[2];
      throw new ShowableError(fullMessage, _('asis'), undefined, {
        message,
        id,
      });
    } else {
      throw new ShowableError(fullMessage, _('error.network'));
    }

  }

  public async logIn(username: string | undefined, password: string | undefined) {
    if (!username) {
      throw new ShowableError('username not set', _('login.error.username_password_required'));
    }
    if (!password) {
      throw new ShowableError('password not set', _('login.error.username_password_required'));
    }
    const result = await this.networkactivity(() => firstValueFrom(this.loginReq.mutate({
      username,
      password
    })));
    const token = result.data?.login;
    if (!token) {
      throw new Error('server did not return a token');
    }
    this.tokenSvc.useToken(token);
  }

  public async logInCandidate(exam: string | undefined, distinguisher: string | undefined) {
    if (!exam) {
      throw new ShowableError('exam not set');
    }
    if (!distinguisher) {
      throw new ShowableError('distinguisher not set');
    }
    const result = await this.networkactivity(() => firstValueFrom(this.loginCandidateReq.mutate({
      exam,
      distinguisher
    })));
    this.tokenSvc.useToken(result.data?.loginCandidate);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { formatDate } from 'devextreme/localization';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { AppInitService } from '../app-init.service';
import { _ } from '../dx_helper';
import { calculateMastery, filterUndefined, insertSpaces } from '../helper';
import * as Types from '../its-organizer-api.types.g';
import { PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';

type TAN = Q.ITangroupreport_DataQuery['tanGroup']['tans'][0];
type Objective = NonNullable<TAN['objectives']>[0];

@Component({
  selector: 'app-tangroup-report',
  templateUrl: './tangroup-report.component.html',
  styleUrls: ['./tangroup-report.component.css']
})
export class TangroupReportComponent implements OnInit {

  public examDate?: string;
  public id: string;
  public tans?: TAN[];
  public tcName?: string;
  public tcKey?: string;
  public name?: string;
  public info?: {
    scoreMax: number;
    mastery: number | undefined;
    module: string;
  };
  public hasMastery?: boolean;
  public displayObjectiveId?: boolean;

  public objectives?: Objective[];

  formatTan(tan: TAN) {
    return insertSpaces(tan.tan);
  }

  formatStatus(tan: TAN) {
    if (tan.status === Types.TanStatus.Open) {
      return this.translate.instant(_('tangroupreport.tanstatus.open'));
    }
    if (tan.status === Types.TanStatus.Inprogress) {
      return this.translate.instant(_('tangroupreport.tanstatus.inprogress'));
    }
    if (tan.score && tan.score.mastery) {
      if (tan.score.gained >= tan.score.mastery) {
        return this.translate.instant(_('tangroupreport.tanstatus.passed'));
      } else {
        return this.translate.instant(_('tangroupreport.tanstatus.failed'));
      }
    }
    return this.translate.instant(_('tangroupreport.tanstatus.participated'));
  }
  formatPercent(o: { gained: number, max: number } | null | undefined) {
    if (!o) {
      return '';
    }
    if (!o.max) {
      return '';
    }
    return Math.round(o.gained * 100 / o.max).toFixed(2);
  }
  constructor(
    private readonly qSvc: Q.ITangroupreport_DataGQL,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private initSvc: AppInitService,
    private svc: PreparationExamService) {

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!id) {
      throw new Error('Parameter id not set');
    }
    this.id = id;
  }


  async ngOnInit() {
    const settings = await this.initSvc.getSettings();
    const r = await firstValueFrom(this.qSvc.fetch({
      id: this.id,
      password: this.svc.getTanGroupPassword(this.id)
    }));

    this.displayObjectiveId = !settings.hideObjectiveId;

    const tg = r.data.tanGroup;
    this.name = tg.name;
    this.tcName = tg.testcenter.name;
    this.tcKey = tg.testcenter.testcenterId;
    this.tans = tg.tans.filter(x => x.status !== Types.TanStatus.Open);
    this.info = {
      module: tg.module.shortName,
      scoreMax: filterUndefined(this.tans.map(x => x.score?.max)).reduce((a, b) => a > b ? a : b, 0),
      mastery: calculateMastery(this.tans.map(x => x.score))
    };
    this.hasMastery = (this.info.mastery ?? 0) > 0;
    const startDate = filterUndefined(this.tans.map(x => x.startTime)).sort((a, b) => a.localeCompare(b));
    this.examDate = startDate.length ? formatDate(moment(startDate[0]).toDate(), { type: 'longDate' }) : undefined;


    const objectives = new Map<string, Objective>();
    for (const tan of tg.tans) {
      if (!tan.objectives) {
        continue;
      }
      for (const obj of tan.objectives) {
        if (objectives.has(obj.key)) {
          const tgt = objectives.get(obj.key)!;
          tgt.score.gained += obj.score.gained;
          tgt.score.pending += obj.score.pending;
          tgt.score.lost += obj.score.lost;
          tgt.score.max += obj.score.max;
        } else {
          objectives.set(obj.key, obj);
        }
      }
    }
    this.objectives = Array.from(objectives.keys()).sort().map(x => objectives.get(x)!);
  }

}

<div class="dx-viewport" style="margin:20px;">
  <div class="page-header">
    <div style="text-align: right;padding-right:20px"><img src="/assets/images/logoheader_its.png"></div>
    <div class="reportheader">{{'tanreport.header'|translate}}</div>
  </div>
  <table style="width:100%">
    <thead>
      <tr>
        <td>
          <div class="page-header-space"></div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>

          <div class="header">
            <div><b>{{tan}}</b></div>
            <div><b>{{tanName}}</b></div>
            <div><span class="exam_label">{{'tanreport.examlabel'|translate}}</span><br /><span
                class="module">{{module}}</span></div>
            <div>{{'tanreport.score' | translate:score}}</div>
            <div><b>{{grade}}</b></div>
          </div>

          <div style="display:flex">
            <table style="flex:1">
              <tr>
                <td><b>{{'tanreport.result'|translate}}</b></td>
                <td style="width:100%">
                  <app-score style="display:inline-block;width:100px" [score]="score">
                  </app-score> {{formatPercent(score)}}% <span *ngIf="hasMastery">{{'tanreport.mastery'|translate:info}}</span>
                </td>
              </tr>
              <tr>
                <td><b>{{'tanreport.testcenter'|translate}}</b></td>
                <td style="width:100%">{{tcKey}} {{tcName}}</td>
              </tr>
              <tr>
                <td><b>{{'tanreport.examname'|translate}}</b></td>
                <td style="width:100%">{{tgName}}</td>
              </tr>
              <tr>
                <td><b>{{'tanreport.examdate'|translate}}</b></td>
                <td style="width:100%">{{examDate}}</td>
              </tr>
            </table>
            <table style="flex:1">
              <tr *ngFor='let item of metadata'>
                <td><b>{{item.label}}:</b></td>
                <td style="width:100%">{{item.value}}</td>
              </tr>
            </table>
          </div>

          <div class="section">
            <h3>{{'tanreport.objectives'|translate}}</h3>
            <table>
              <tr *ngFor="let obj of objectives">
                <td *ngIf="displayObjectiveId">{{obj.key}}</td>
                <td>
                  <app-score style="display:block;width:100px" [score]="obj.score"></app-score>
                </td>
                <td style="text-align:center">{{obj.score.gained}}/{{obj.score.max}}</td>
                <td style="text-align: right">{{formatPercent(obj.score)}}%</td>
                <td>{{obj.description}}</td>
              </tr>
            </table>
          </div>
          <div class="section">
            <h3>{{'tanreport.items'|translate}}</h3>
            <div class="item" *ngFor='let item of items'>
              <div class="index">{{item.index}}</div>
              <div class="itemid">{{item.itemId}}</div>
              <div class="score">
                <app-score [score]="item.score"></app-score>
              </div>
            </div>
            <div class="dx-clearfix"></div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="dx-viewport" style="margin:20px;">
    <div class="page-header">
      <div class="page-header-image"><img src="/assets/images/logoheader_its.png"></div>
      <div class="reportheader">{{ 'candidate.report.title' | translate }}</div>
    </div>
    <table style="width:100%">
      <thead>
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
  
            <div class="header">
              <div><b>{{reference}}</b></div>
              <div><b>{{candidateName}}</b></div>
              <div><span class="exam_label">{{ 'candidate.report.exam' | translate }}</span><br /><span class="module">{{module}}</span></div>
              <div>{{formatNumber(score && score.gained)}} von {{formatNumber(score && score.max)}} Punkten</div>
              <div><b>{{grade}}</b></div>
            </div>
            <table>
              <tr>
                <td><b>Ergebnis:</b></td>
                <td>
                  <app-score style="display:inline-block;width:100px" [score]="score">
                  </app-score> {{formatScore(score)}} {{formatPercent(score)}} <span *ngIf="hasMastery">({{mastery}} oder mehr Punkte sind zum
                    Bestehen
                    der Prüfung
                    erforderlich)</span>
                </td>
              </tr>
              <tr>
                <td><b>Testcenter:</b></td>
                <td>{{tcKey}} {{tcName}}</td>
              </tr>
              <tr>
                <td><b>Prüfungsdatum:</b></td>
                <td>{{examDate}}</td>
              </tr>
            </table>
  
            <div class="section">
              <h3 style="text-align: center;">----------- Detailauswertung ----------- </h3>
              <table class="objectives">
                <tr *ngFor="let obj of objectives" [class]="obj.depthClass">
                  <td class="objective-key">{{obj.key}}</td>
                  <td class="objective-description">{{obj.description}}</td>
                  <td class="objective-bar">
                    <app-score style="display:block;width:100px;margin-top:3px" [score]="obj.score"></app-score>
                  </td>
                  <td class="objective-score-points" style="text-align:center">{{formatScore(obj.score)}}</td>
                  <td class="objective-score-percent" style="text-align: right">{{formatPercent(obj.score)}}</td>
                </tr>
              </table>
            </div>
            <div class="section">
              <h3>Fragen</h3>
              <div class="item" *ngFor='let item of items'>
                <div class="index">{{item.index}}</div>
                <div class="itemid">{{formatItem(item)}}</div>
                <div class="score">
                  <app-score style="display:block;width:95px" [score]="item.score"></app-score>
                </div>
              </div>
              <div class="dx-clearfix"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
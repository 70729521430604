
// tslint:disable
export const LANG: {[key:string]: {[key:string]:string }} = {

 "de-x-ba": 
{
  "preparation.dashboard.button.delete": "Ausgewählte Assessments löschen (benötigt Kennwort)",
  "preparation.dashboard.button.new_tangroup": "Neues Assessment",
  "preparation.dashboard.confirm.delete": "Möchten Sie wirklich {{count}} Assessments löschen?",
  "preparation.dashboard.dialog.new.fields.name": "Indiv. Bezeichnung",
  "preparation.dashboard.dialog.new.title": "Neues Assessment",
  "preparation.dashboard.error.allowance": "Sie können kein Assessment anlegen, da Sie das Kontingent von {{max}} TANs erreicht haben.",
  "preparation.dashboard.error.delete": "{{count}} Assessment(s) konnten nicht gelöscht werden!",
  "preparation.dashboard.grid.name": "Bezeichnung",
  "preparation.dashboard.title": "Assessments verwalten - Übersicht",
  "preparation.tangroup.button.finish": "Assessment abschließen + Gesamtbericht",
  "preparation.tangroup.button.launch": "Assessment Modul aufrufen / einsehen",
  "preparation.tangroup.confirm.close": "Wollen Sie das Assessment abschließen?",
  "preparation.tangroup.grid.barometer": "Ergebnisse",
  "preparation.tangroup.title": "Assessment",
  "login.product_name": ""
}
,
 "de-x-dcp": 
{
  "preparation.dashboard.button.delete": "Ausgewählte Diagnoseprüfungen löschen (benötigt Kennwort)",
  "preparation.dashboard.button.new_tangroup": "Neues Diagnoseprüfung",
  "preparation.dashboard.confirm.delete": "Möchten Sie wirklich {{count}} Diagnoseprüfung(en) löschen?",
  "preparation.dashboard.dialog.new.title": "Neue Diagnoseprüfung",
  "preparation.dashboard.error.allowance": "Sie können keine Diagnoseprüfung anlegen, da Sie das Kontingent von {{max}} TANs erreicht haben.",
  "preparation.dashboard.error.delete": "{{count}} Diagnoseprüfung(en) konnten nicht gelöscht werden!",
  "preparation.dashboard.title": "Diagnoseprüfungen verwalten - Übersicht",
  "preparation.tangroup.button.finish": "Diagnoseprüfung abschließen + Gesamtbericht",
  "preparation.tangroup.button.launch": "Diagnoseprüfung Modul aufrufen / einsehen",
  "preparation.tangroup.confirm.close": "Wollen Sie die Diagnoseprüfung abschließen?",
  "preparation.tangroup.title": "Diagnoseprüfung"
}
,
 "de-x-digi": 
{
  "preparation.dashboard.button.delete": "Ausgewählte digi.checks löschen (benötigt Kennwort)",
  "preparation.dashboard.button.new_tangroup": "Neuer digi.check",
  "preparation.dashboard.confirm.delete": "Möchten Sie wirklich {{count}} digi.checks löschen?",
  "preparation.dashboard.dialog.new.allowance": "Kontingent: Noch {{remaining}} TANs verfügbar",
  "preparation.dashboard.dialog.new.fields.name": "Bezeichnung",
  "preparation.dashboard.dialog.new.title": "Neuer digi.check",
  "preparation.dashboard.error.allowance": "Sie können keinen digi.check anlegen, da Sie das Kontingent von {{max}} TANs erreicht haben.",
  "preparation.dashboard.error.delete": "{{count}} digi.check(s) konnten nicht gelöscht werden!",
  "preparation.dashboard.grid.name": "Bezeichnung",
  "preparation.dashboard.title": "digi.checks verwalten - Übersicht",
  "preparation.tangroup.button.finish": "digi.check abschließen + Gesamtbericht",
  "preparation.tangroup.button.launch": "digi.check gemeinsam begutachten",
  "preparation.tangroup.confirm.close": "Wollen Sie den digi.check abschließen?",
  "preparation.tangroup.grid.barometer": "Ergebnisse",
  "preparation.tangroup.title": "digi.check"
}
,
 "de-x-ecdl-edu": 
{
  "tanreport.testcenter": "Schule:",
  "preparation.dashboard.label.testcenter": "Schule:",
  "cert.dashboard.grid.testcenter": "Prüfer*in",
  "newcertexam.field.testcenter": "Prüfer*in"

}
,
 "de-x-lavak": 
{
  "candidate.overview.title": "Dienstprüfung",
  "cert.dashboard.grid.certificate": "Zertifikat",
  "cert.dashboard.header.current": "Offene Prüfungstermine",
  "cert.dashboard.header.current_hint": "",
  "cert.dashboard.header.recent": "Abgeschlossene Dienstprüfungen",
  "cert.dashboard.header.recent_hint": "(der letzten 14 Tage)",
  "cert.dashboard.title": "Dienstprüfungen - Übersicht",
  "cert.exam.button.finish": "Dienstprüfung abschließen + Gesamtbericht",
  "cert.exam.title": "Dienstprüfung"
}
,
 "de-x-rm": 
{
  "candidate.overview.start":"Fragegruppe starten",
  "cert.dashboard.grid.certificate": "",
  "cert.dashboard.header.current": "Offene Standortbestimmungen",
  "cert.dashboard.header.current_hint": "",
  "cert.dashboard.header.recent": "Abgeschlossene Standortbestimmungen",
  "cert.dashboard.header.recent_hint": "",
  "cert.dashboard.title": "Standortbestimmungen - Übersicht",
  "cert.exam.button.finish": "Standortbestimmung abschließen + Gesamtbericht generieren",
  "cert.exam.title": "Standortbestimmung",
  "cert.dashboard.grid.modules": "Fragegruppen",
  "cert.dashboard.grid.go": "",
  "cert.dashboard.grid.gobutton": "Öffnen",
  "cert.dashboard.grid.date": "Startdatum",
  "cert.exam.grid.module": "Fragegruppe",
  "cert.exam.grid.modulegroup": "Modulgruppe",
  "cert.exam.grid.topic": "Kategorie",
  "cert.exam.grid.passed": "",
  "cert.exam.label.examid": "ID der Standortbestimmung",
  "cert.exam.message.completed": "BEENDET",
  "cert.exam.message.paused": "ANGEHALTEN bei {{progress}}%",
  "candidate.report.title":"Ressourcen-Mosaik 2.0",
  "candidate.report.exam":"Fragegruppe",
  "candidate.report.grade":"",
  "login.placeholder.examid": "ID der Standortbestimmung",
  "login.placeholder.name": "Vorname Nachname (oder Kandidaten-Kürzel)",
  "login.login_as_candidate": "Kandidat:in",
  "login.login_as_proctor": "Administrator:in",
  "cert.exam.label.examdate": "Startdatum",
  "cert.exam.tab.overview": "Anmeldestatus",
  "cert.exam.tab.progress": "Detailübersicht",
  "cert.exam.grid.candidates": "Kandidat:innen",
  "cert.exam.label.name": "Name der Standortbestimmung",
  "cert.exam.grid.examstarted": "Gestartet",
  "newcertexam.button.import": "Neue Standortbestimmung anlegen",
  "newcertexam.field.file": "Exceldatei für Fragegruppenimport",
  "newcertexam.field.examdate": "Startdatum",
  "newcertexam.field.testcenter": "Testcenter",
  "cert.dashboard.newexamdlg.title": "Neue Standortbestimmung",
  "cert.dashboard.button.newexam": "Neue Standortbestimmung",
  "candidate.overview.title": "Standortbestimmung",
  "candidate.overview.header": "Ihre Fragegruppen",
  "candidate.overview.hint": "Mit Klick auf \"Fragegruppe starten\" gelangen Sie zu den Fragen dieser Fragegruppe! Mit Klick auf eine Fragenkategorie, öffnen Sie die Fragegruppen von dieser Kategorie. Wenn Sie eine Pause machen möchten, melden Sie sich ab. Wenn Sie alle Fragegruppen erledigt haben, beenden Sie die Standortbestimmung, indem Sie sich abmelden.",
  "candidate.overview.alert.disabled": "Diese Standortbestimmung steht nicht mehr zur Verfügung!<br />Entweder haben Sie diese Standortbestimmung bereits absolviert oder sie wurde bereits abgeschlossen.",
  "candidate.overview.confirm.launch_title": "Standortbestimmung staren?",
  "candidate.overview.error.launch": "Die Standortbestimmung konnte nicht gestartet werden."
}
,
 "de": 
{
    "oidc.not-a-proctor.title": "OIDC Prüfer:in Login",
    "oidc.not-a-proctor.message": "Es sind noch keine Prüfungstermine für Sie vorhanden.",
    "itsclient-required.title": "Benötigte Version des ITSR3 Clients: {{minVersion}}",
    "itsclient-required.version_mismatch_title": "Benötigte Version des ITSR3 Clients: {{minVersion}} - Sie verwenden aber {{curVersion}}.",
    "itsclient-required.open": "<p>Haben Sie die benötigte oder eine höhere Version des ITS<sup>R3</sup> Clients installiert, klicken Sie auf <b>ITSR3 Client öffnen</b>.",
    "itsclient-required.install": "<p>Haben Sie den ITSR3 Client noch nicht installiert, klicken Sie auf <b>ITSR3 Client herunterladen</b>. Installieren Sie den Client und starten anschließend die Prüfung hier über den Button <b>ITSR3 Client öffnen</b>.",
    "itsclient-required.version_mismatch": "Sie haben nicht die benötigte Version des ITSR3 Clients installiert. Bitte klicken Sie auf <b>ITSR3 Client herunterladen</b>, deinstallieren Sie die alte Version, installieren Sie den neuen ITSR3 Client und öffnen Sie den ITSR3 Client im Anschluss über das Browserfenster.",
    "itsclient-required.action.open": "ITS Client öffnen",
    "itsclient-required.action.install": "ITS Client herunterladen",
    "tangroupreport.tanstatus.open": "Nicht teilgenommen",
    "tangroupreport.tanstatus.inprogress": "Aktive Prüfung",
    "tangroupreport.tanstatus.passed": "Bestanden",
    "tangroupreport.tanstatus.failed": "Nicht bestanden",
    "tangroupreport.tanstatus.participated": "Teilgenommen",
    "tangroupreport.header": "Prüfungsbericht",
    "tangroupreport.testcenter": "Testcenter:",
    "tangroupreport.examname": "Prüfungsname:",
    "tangroupreport.examdate": "Prüfungsdatum:",
    "tangroupreport.module": "Prüfung: {{module}}",
    "tangroupreport.scoremax": "Für dieses Modul bekommt man bis zu {{scoreMax}} Punkte.",
    "tangroupreport.mastery": "Mit {{mastery}} Punkten oder mehr gilt die Prüfung als bestanden.",
    "tangroupreport.section.candidates": "Kandidaten und Kandidatinnen",
    "tangroupreport.score": "{{gained}} Punkte",
    "tangroupreport.section.objectives": "Punkteverteilung",
    "tanreport.header": "Prüfungsbericht",
    "tanreport.examlabel": "Prüfung",
    "tanreport.score": "{{gained}} von {{max}} Punkten",
    "tanreport.result": "Ergebnis:",
    "tanreport.mastery": "({{mastery}} oder mehr Punkte sind zum Bestehen der Prüfung erforderlich.)",
    "tanreport.testcenter": "Testcenter:",
    "tanreport.examname": "Prüfungsname:",
    "tanreport.examdate": "Prüfungsdatum:",
    "tanreport.objectives": "Punkteverteilung",
    "tanreport.items": "Fragen",
    "asis": "{{message}} ({{id}})",
    "button.refresh": "Aktualisieren",
    "candidate.overview.alert.disabled": "Diese Prüfung steht nicht mehr zur Verfügung!<br />Entweder haben Sie diese Prüfung bereits absolviert oder der Prüfungstermin wurde von der Prüferin / dem Prüfer bereits abgeschlossen.",
    "candidate.overview.alert.disabled_title": "Organizer",
    "candidate.overview.button.logout": "Abmelden",
    "candidate.overview.button.refresh": "Aktualisieren",
    "candidate.overview.confirm.launch_title": "Prüfung starten?",
    "candidate.overview.demostart": "Demo starten",
    "candidate.overview.error.launch": "Die Prüfung konnte nicht gestartet werden.",
    "candidate.overview.error.launch_title": "Organizer",
    "candidate.overview.header": "Ihre Prüfungen",
    "candidate.overview.hint": "Mit Klick auf die Prüfungskachel wird die Prüfung gestartet. Haben Sie Ihre Prüfungstätigkeiten beendet, schließen Sie die Prüfungsübersicht, indem Sie sich abmelden.",
    "candidate.overview.title": "Zertifizierungsprüfung",
    "candidate.overview.start": "Modul starten",
    "candidate.overview.examPopupOpen": "Die Prüfung wird in einem anderen Fenster ausgeführt",
    "default_topic": "Modul",    
    "newcertexam.button.sample": "Vorlage herunterladen",
    "newcertexam.button.import": "Neue Prüfung anlegen",
    "newcertexam.field.file": "Datei",
    "newcertexam.field.name": "Name",
    "newcertexam.field.examdate": "Prüfungsdatum",
    "newcertexam.field.testcenter": "Testcenter",
    "cert.dashboard.newexamdlg.title": "Neue Prüfung",
    "cert.dashboard.button.logout": "Abmelden",
    "cert.dashboard.button.refresh": "Aktualisieren",
    "cert.dashboard.button.newexam": "Neue Prüfung",
    "cert.dashboard.grid.certificate": "Zertifikat",
    "cert.dashboard.grid.date": "Datum",
    "cert.dashboard.grid.name": "Name",
    "cert.dashboard.grid.go": "GO",
    "cert.dashboard.grid.gobutton": "Go",
    "cert.dashboard.grid.key": "ID",
    "cert.dashboard.grid.modules": "Module",
    "cert.dashboard.grid.results": "Ergebnisdateien (zip)",
    "cert.dashboard.grid.testcenter": "Testcenter",
    "cert.dashboard.header.current": "Aktuelle Prüfungstermine",
    "cert.dashboard.header.current_hint": "(heutiger Tag)",
    "cert.dashboard.header.recent": "Abgeschlossene Prüfungen",
    "cert.dashboard.header.recent_hint": "(der letzten 14 Tage)",
    "cert.dashboard.message.notfinished": "Die Prüfung wurde noch nicht abgeschlossen.",
    "cert.dashboard.title": "Zertifikatsprüfungen - Übersicht",
    "cert.exam.button.back": "Zurück",
    "cert.exam.button.finish": "Zertifizierungsprüfung abschließen + Gesamtbericht",
    "cert.exam.button.refresh": "Aktualisieren",
    "cert.exam.button.uploadxlsx": "XSLX aktualisieren",
    "cert.exam.button.downloadxlsx": "XLSX speichern",
    "cert.exam.confirm.finish": "Möchten Sie den Prüfungstermin wirklich abschließen?",
    "cert.exam.confirm.finish_title": "Achtung!",
    "cert.exam.grid.candidates": "Kandidaten",
    "cert.exam.updatedlg.title": "XLSX aktualisieren",
    "cert.exam.updatedlg.button.import": "Aktualisieren",
    "cert.exam.updatedlg.field.file": "Datei",
    "cert.exam.grid.examstarted": "Prüfung gestartet",
    "cert.exam.grid.timepassed": "Zeit im Test",
    "cert.exam.grid.file": "Datei",
    "cert.exam.grid.attempt": "Versuch",
    "cert.exam.grid.reset.text": "Zurücksetzen",
    "cert.exam.grid.reset.hint": "Zurücksetzen und dem Kandidaten/der Kandidatin einen neuen Versuch erlauben",
    "cert.exam.confirm.reset": "Der Kandidat/die Kandidatin wird alle bisherigen Ergebnisse verlieren. Wollen Sie einen neuen Versuch erlauben?",
    "cert.exam.confirm.reset_title": "Zurücksetzen",
    "cert.exam.grid.loggedin": "Angemeldet",
    "cert.exam.grid.module": "Modul",
    "cert.exam.grid.passed": "Bestanden",
    "cert.exam.grid.present": "Anwesend",
    "cert.exam.grid.progress": "Fortschritt (%)",
    "cert.exam.grid.result": "Ergebnisdateien",
    "cert.exam.grid.results": "Ergebnisdatei (PDF)",
    "cert.exam.grid.modulegroup": "Modulgruppe",
    "cert.exam.grid.topic": "Thema",
    "cert.exam.label.allReportsAsZip": "Alle Berichte als ZIP",
    "cert.exam.label.examReport": "Gesamtbericht",
    "cert.exam.label.interimExamReport": "Zwischenbericht",
    "cert.exam.label.name": "Prüfungsname",
    "cert.exam.label.examid": "PrüfungsID",
    "cert.exam.label.personsPresent": "Teilnehmerzahl",
    "cert.exam.label.testcenter": "Testcenter",
    "cert.exam.label.examdate": "Prüfungsdatum",
    "cert.exam.message.completed": "PRÜFUNG BEENDET",
    "cert.exam.message.finished": "ABGESCHLOSSEN",
    "cert.exam.message.open": "Noch nicht begonnen",
    "cert.exam.message.paused": "PRÜFUNG ANGEHALTEN bei {{progress}}%",
    "cert.exam.tab.overview": "Kandidaten & Anmeldung",
    "cert.exam.tab.progress": "Prüfungsaufsicht & Abschluss",
    "cert.exam.tab.support": "Support",
    "cert.exam.title": "Zertifizierungsprüfung",
    "common.errordlg_msg": "Ein Fehler ist aufgetreten.",
    "common.errordlg_title": "Fehler",
    "common.no": "Nein",
    "common.yes": "Ja",
    "error.network": "Ein Netzwerkproblem ist aufgetreten.",
    "itsr3redirect.header": "ITS<sup>R3</sup> SOFTWARE (CLIENT) bereits INSTALLIERT?",
    "itsr3redirect.no": "NEIN – Installieren Sie den Client und melden Sie sich erneut an.",
    "itsr3redirect.no_explain": "Fenster schließen - itsr3.exe über das Portal -> Reiter <b>ITS<sup>R3</sup> Software</b> downloaden und ausführen / keine Admin-Rechte erforderlich - Client schließen und über das Portal einsteigen",
    "itsr3redirect.yes": "JA – Bestätigen Sie das Öffnen von ITS<sup>R3</sup> im Dialogfenster.",
    "itsr3redirect.yes_explain": "Sollte die Anmeldeseite des Clients von anderen Fenstern verdeckt sein, klicken Sie auf das <b>ITS-Symbol</b> in der <b>Taskleiste</b>.",
    "login.button.login": "Anmelden",
    "login.button.sso": "Anmelden",
    "login.product_name": "ITS<sup>R3</sup> Organizer",
    "login.certification_title": "",
    "login.confirm.launch_tan_title": "Starten?",
    "login.error.invalid_tan": "Der TAN-Code ist ungültig",
    "login.error.login": "Login nicht möglich.<br/>Anmeldedaten inkorrekt oder keine Berechtigung.",
    "login.error.login_title": "Hinweis",
    "login.error.tan_required": "Sie müssen einen TAN-Code eingeben.",
    "login.error.username_password_required": "Sie müssen Benutzername und Kennwort eingeben.",
    "login.header_candidate": "Kandidaten-Anmeldung",
    "login.header_tan": "Kandidaten-Anmeldung (TAN)",
    "login.header_testcenter": "Anmeldung",
    "login.login_as": "Anmelden als:",
    "login.login_as_candidate": "Kandidat:in",
    "login.login_as_proctor": "Prüfer:in",
    "login.login_as_tan": "Kandidat:in (TAN)",
    "login.login_as_testcenter": "Organisator:in",
    "login.metadata.button.cancel": "Abbrechen",
    "login.metadata.button.ok": "OK",
    "login.metadata.title": "Starten",
    "login.placeholder.examid": "PrüfungsID",
    "login.placeholder.name": "Vorname Nachname",
    "login.placeholder.password": "Kennwort",
    "login.placeholder.username": "Benutzerkennung",
    "login.preparation_title": "",
    "nyi": "Noch nicht implementiert.",
    "preparation.dashboard.button.delete": "Ausgewählte Prüfungen löschen (benötigt Kennwort)",
    "preparation.dashboard.button.info": "Info",
    "preparation.dashboard.button.new_tangroup": "Neue Vorbereitungsprüfung",
    "preparation.dashboard.button.open": "Öffnen",
    "preparation.dashboard.button.refresh": "Aktualisieren",
    "preparation.dashboard.confirm.delete": "Möchten Sie wirklich {{count}} Vorbereitungsprüfung(en) löschen?",
    "preparation.dashboard.confirm.delete_title": "Löschen",
    "preparation.dashboard.dialog.new.allowance": "Kontingent: Noch {{remaining}} TANs verfügbar",
    "preparation.dashboard.dialog.new.button.create": "Anlegen",
    "preparation.dashboard.dialog.new.fields.module": "Modul",
    "preparation.dashboard.dialog.new.fields.name": "Prüfungsname",
    "preparation.dashboard.dialog.new.fields.password": "Kennwort",
    "preparation.dashboard.dialog.new.fields.tans": "Anzahl TANs",
    "preparation.dashboard.dialog.new.fields.valid_to": "Gültig bis",
    "preparation.dashboard.dialog.new.title": "Neue Vorbereitungsprüfung",
    "preparation.dashboard.error.allowance": "Sie können keine Vorbereitungsprüfung anlegen, da Sie das Kontingent von {{max}} TANs erreicht haben.",
    "preparation.dashboard.error.allowance_title": "Hinweis",
    "preparation.dashboard.error.delete": "{{count}} Vorbereitungsprüfung(en) konnten nicht gelöscht werden!",
    "preparation.dashboard.error.delete_title": "Fehler beim Löschen",
    "preparation.dashboard.error.invalid_password": "Falsches Kennwort!",
    "preparation.dashboard.error.invalid_password_title": "Fehler",
    "preparation.dashboard.error.password_needed": "Sie müssen zuerst ein Kennwort eingeben!",
    "preparation.dashboard.error.password_needed_title": "Hinweis",
    "preparation.dashboard.error.unable_to_create_new_exam": "Es konnte keine neue Prüfung angelegt werden!",
    "preparation.dashboard.error.unable_to_create_new_exam_title": "Fehler",
    "preparation.dashboard.filter.done_false": "Nicht abgeschlossen",
    "preparation.dashboard.filter.done_true": "Abgeschlossen",
    "preparation.dashboard.grid.done": "Abgeschlossen",
    "preparation.dashboard.grid.finished_count": "Fertige Kandidaten",
    "preparation.dashboard.grid.id": "(ID)",
    "preparation.dashboard.grid.module_shortname": "Modul",
    "preparation.dashboard.grid.name": "Prüfungsname",
    "preparation.dashboard.grid.password": "Kennwort Eingabe",
    "preparation.dashboard.grid.tan_count": "Anzahl TANs",
    "preparation.dashboard.grid.valid_to": "Gültig bis",
    "preparation.dashboard.label.testcenter": "Testcenter:",
    "preparation.dashboard.header": "Übersicht",
    "preparation.dashboard.title": "Vorbereitungsprüfungen verwalten - Übersicht",
    "preparation.tangroup.button.candidatepdf": "Ergebnis PDF",
    "preparation.tangroup.button.export": "TAN-Liste speichern / bearbeiten",
    "preparation.tangroup.button.finish": "Vorbereitungsprüfung abschließen + Gesamtbericht",
    "preparation.tangroup.button.import": "Bearbeitete TAN-Liste importieren",
    "preparation.tangroup.button.launch": "Vorbereitungsprüfung gemeinsam begutachten",
    "preparation.tangroup.button.printoverview": "Übersicht drucken",
    "preparation.tangroup.button.refresh": "Aktualisieren",
    "preparation.tangroup.button.results": "Gesamtbericht",
    "preparation.tangroup.confirm.close": "Wollen Sie die Vorbereitungsprüfung abschließen?",
    "preparation.tangroup.confirm.close_title": "Prüfungsabschluss",
    "preparation.tangroup.error.close": "Beim Abschließen der TAN-Gruppe ist etwas schiefgegangen.",
    "preparation.tangroup.label.download_detail_report": "Laden Sie hier Ihren Detailbericht herunter:",
    "preparation.tangroup.label.detailview": "Detailergebnis:",
    "preparation.tangroup.detailview.objective": "{{id}} {{description}} ({{gained}}/{{max}})",
    "preparation.tangroup.hint.detailview": "Klicken Sie auf den Ergebnisbalken, um die Detailergebnisse und den Einzelbericht einer Kandidatin / eines Kandidaten anzuzeigen.",
    "preparation.tangroup.error.close_title": "Error",
    "preparation.tangroup.error.invalidxlsx": "Import nicht möglich.<br/>Sie haben eine falsche oder fehlerhafte TAN-Liste verwendet.",
    "preparation.tangroup.error.invalidxlsx_title": "Fehler",
    "preparation.tangroup.error.launch": "Der Test kann nicht gestartet werden.",
    "preparation.tangroup.error.launch_title": "Fehler",
    "preparation.tangroup.grid.barometer": "Gesamtergebnis",
    "preparation.tangroup.grid.file": "Datei",
    "preparation.tangroup.grid.for": "Name (TAN für...)",
    "preparation.tangroup.grid.id": "(ID)",
    "preparation.tangroup.grid.incorrect": "Anzahl 'Falsch gelöst':",
    "preparation.tangroup.grid.items": "Fragen",
    "preparation.tangroup.grid.nr": "Nr.",
    "preparation.tangroup.grid.result": "Berichte",
    "preparation.tangroup.grid.results": "Ergebnisdatei (PDF)",
    "preparation.tangroup.grid.tan": "TANs",
    "preparation.tangroup.grid.used": "TAN eingelöst",
    "preparation.tangroup.status.completed": "Fertig",
    "preparation.tangroup.status.correct": "Richtig",
    "preparation.tangroup.status.current": "Aktuell",
    "preparation.tangroup.status.incorrect": "Falsch",
    "preparation.tangroup.status.open": "Offen",
    "preparation.tangroup.status.partiallycorrect": "Teilweise richtig",
    "preparation.tangroup.status.postponed": "Aufgeschoben",
    "preparation.tangroup.tab.overview": "Übersicht & TAN-Codes",
    "preparation.tangroup.tab.progress": "Fortschritt & Bewertung",
    "preparation.tangroup.tab.results": "Abschluss & Ergebnisse",
    "preparation.tangroup.tab.support": "Support",
    "preparation.tangroup.title": "Vorbereitungsprüfung",
    "preparation.tangroup.valid_to": "Gültig bis",
    "preparation.tanoverview.button.print": "Drucken",
    "candidate.report.title": "Prüfungsbericht",
    "candidate.report.exam": "Prüfung"
}

,
 "en-dev": 
{
    "preparation.tangroup.detailview.objective": "{{id}} {{description}} ({{gained}}/{{max}}) {{percent}}%"
}
,
 "en": 
{
    "oidc.not-a-proctor.title": "OIDC Proctor Login",
    "oidc.not-a-proctor.message": "There are no exams for you",
    "itsclient-required.title": "ITS Client {{minVersion}} required",
    "itsclient-required.open": "<p>If the required or a higher version of the ITSR3 Client is installed, click on <b>Open in ITSR3 Client</b>.",
    "itsclient-required.install": "<p>If the ITSR3 Client is not yet installed on your computer, click on <b>Download ITSR3 Client</b>, install the client and then continue here by clicking on <b>Open in ITSR3 Client</b>",
    "itsclient-required.version_mismatch_title": "ITS Client {{minVersion}} required but you have {{curVersion}}",
    "itsclient-required.version_mismatch": "You do not have the required version of the ITSR3 client installed on your computer. Please click on <b>Download ITSR3 Client</b>, uninstall the outdated version, install the new ITSR3 Client and open it via the Browser window.",
    "itsclient-required.action.open": "Open in ITSR3 Client",
    "itsclient-required.action.install": "Download ITSR3 Client",
    "tangroupreport.tanstatus.open": "Not attempted",
    "tangroupreport.tanstatus.inprogress": "Exam In Progress",
    "tangroupreport.tanstatus.passed": "Passed",
    "tangroupreport.tanstatus.failed": "Failed",
    "tangroupreport.tanstatus.participated": "Participated",
    "tangroupreport.header": "Exam Report",
    "tangroupreport.testcenter": "Testcenter:",
    "tangroupreport.examname": "Exam name:",
    "tangroupreport.examdate": "Exam date:",
    "tangroupreport.scoremax": "The maximum score for this module is {{scoreMax}} points.",
    "tangroupreport.mastery": "At least {{mastery}} points are needed to pass this exam.",
    "tangroupreport.module": "Exam: {{module}}",
    "tangroupreport.section.candidates": "Candidates",
    "tangroupreport.score": "{{gained}} points",
    "tangroupreport.section.objectives": "Objectives",
    "tanreport.header": "Exam Report",
    "tanreport.examlabel": "Exam",
    "tanreport.score": "{{gained}} of {{max}} points",
    "tanreport.result": "Result:",
    "tanreport.mastery": "({{mastery}} or more points are necessary to pass the exam)",
    "tanreport.testcenter": "Testcenter:",
    "tanreport.examname": "Exam name:",
    "tanreport.examdate": "Exam date:",
    "tanreport.objectives": "Objectives",
    "tanreport.items": "Items",
    "asis": "{{message}} ({{id}})",
    "button.refresh": "Refresh",
    "candidate.overview.alert.disabled": "This exam is not available anymore. Either you already have completed this exam or the appointment is already closed.",
    "candidate.overview.alert.disabled_title": "Organizer",
    "candidate.overview.button.logout": "Logout",
    "candidate.overview.button.refresh": "Refresh",
    "candidate.overview.confirm.launch_title": "Start exam?",
    "candidate.overview.demostart": "Launch Demo",
    "candidate.overview.error.launch": "Unable to launch exam",
    "candidate.overview.error.launch_title": "Organizer",
    "candidate.overview.header": "Your Exams",
    "candidate.overview.hint": "By clicking on the exam name the exam is started!",
    "candidate.overview.start": "Launch Module",
    "candidate.overview.title": "Certification Exam",
    "candidate.overview.examPopupOpen": "Exam is open in a popup window",
    "default_topic": "Module",
    "newcertexam.button.sample": "Download Sample file",
    "newcertexam.button.import": "New",
    "newcertexam.field.name": "Name",
    "newcertexam.field.file": "File",
    "newcertexam.field.examdate": "Exam Date",
    "newcertexam.field.testcenter": "Testcenter",
    "cert.dashboard.newexamdlg.title": "New Exam",
    "cert.dashboard.button.logout": "Logout",
    "cert.dashboard.button.refresh": "Refresh",
    "cert.dashboard.button.newexam": "New Exam",
    "cert.dashboard.grid.certificate": "Certificate",
    "cert.dashboard.grid.date": "Date",
    "cert.dashboard.grid.name": "Name",
    "cert.dashboard.grid.go": "GO",
    "cert.dashboard.grid.gobutton": "Go",
    "cert.dashboard.grid.key": "ID",
    "cert.dashboard.grid.modules": "Module",
    "cert.dashboard.grid.results": "Results (zip)",
    "cert.dashboard.grid.testcenter": "Testcenter",
    "cert.dashboard.header.current": "Current exam appointsments",
    "cert.dashboard.header.current_hint": "(today)",
    "cert.dashboard.header.recent": "Finished exams",
    "cert.dashboard.header.recent_hint": "(last 14 days)",
    "cert.dashboard.message.notfinished": "The exam was not finished",
    "cert.dashboard.title": "Certification Exams",
    "cert.exam.button.back": "Back",
    "cert.exam.button.finish": "Finish",
    "cert.exam.button.refresh": "Refresh",
    "cert.exam.button.uploadxlsx": "Update",
    "cert.exam.button.downloadxlsx": "Save XLSX",
    "cert.exam.confirm.finish": "Do you really want to finish the exam?",
    "cert.exam.confirm.finish_title": "Confirmation",
    "cert.exam.grid.candidates": "Candidates",
    "cert.exam.updatedlg.title": "Update Exam",
    "cert.exam.updatedlg.button.import": "Update",
    "cert.exam.updatedlg.field.file": "File",
    "cert.exam.grid.examstarted": "Exam s tarted",
    "cert.exam.grid.timepassed": "Time Passed",
    "cert.exam.grid.file": "File",
    "cert.exam.grid.attempt": "Attempt",
    "cert.exam.grid.reset.text": "Reset",
    "cert.exam.grid.reset.hint": "Reset the attempt",
    "cert.exam.confirm.reset": "The candidate will loose the current work and will need to start again from scratch.",
    "cert.exam.confirm.reset_title": "Reset",
    "cert.exam.grid.loggedin": "Logged In",
    "cert.exam.grid.module": "Module",
    "cert.exam.grid.passed": "Passed",
    "cert.exam.grid.present": "Present",
    "cert.exam.grid.progress": "Progress",
    "cert.exam.grid.result": "Result files",
    "cert.exam.grid.results": "Results (PDF)",
    "cert.exam.grid.modulegroup": "Module Group",
    "cert.exam.grid.topic": "Topic",
    "cert.exam.label.allReportsAsZip": "All reports as zip",
    "cert.exam.label.examReport": "Exam Report",
    "cert.exam.label.interimExamReport": "Exam Interim Report",
    "cert.exam.label.name": "Exam Name",
    "cert.exam.label.examid": "Exam Id",
    "cert.exam.label.personsPresent": "Persons present",
    "cert.exam.label.testcenter": "Testcenter",
    "cert.exam.label.examdate": "Exam date",
    "cert.exam.message.completed": "EXAM COMPLETED",
    "cert.exam.message.finished": "FINISHED",
    "cert.exam.message.open": "Not yet started",
    "cert.exam.message.paused": "EXAM STOPPED at {{progress}}%",
    "cert.exam.tab.overview": "Candidates & Login",
    "cert.exam.tab.progress": "Exam proctoring & finishing",
    "cert.exam.tab.support": "Support",
    "cert.exam.title": "Certification Exam",
    "common.errordlg_msg": "An error has occured.",
    "common.errordlg_title": "Error",
    "common.no": "no",
    "common.yes": "yes",
    "error.network": "There was a problem with the network connectivity",
    "itsr3redirect.header": "ITS<sup>R3</sup> CLIENT ALREADY INSTALLED?",
    "itsr3redirect.no": "NO - Close the window, download and install the ITS<sup>R3</sup> Client.",
    "itsr3redirect.no_explain": "Download and install itsr3.exe via the portal -> tab <b>ITS<sup>R3</sup> Software</b> / no admin-rights required<br />Close the client and return to the login page of the portal.",
    "itsr3redirect.yes": "YES - Confirm that you want to open ITS<sup>R3</sup>.",
    "itsr3redirect.yes_explain": "If the client login page is hidden by other windows, click on the <b>ITS icon</b> in the <b>taskbar</b>.",
    "login.button.login": "Login",
    "login.button.sso": "Login",
    "login.product_name": "ITS<sup>R3</sup> Organizer",
    "login.certification_title": "Certification Exam",
    "login.confirm.launch_tan_title": "Start?",
    "login.error.invalid_tan": "Invalid TAN code",
    "login.error.login": "Unable to login",
    "login.error.login_title": "Error",
    "login.error.tan_required": "You need to enter a tan code",
    "login.error.username_password_required": "Both username and password must be set",
    "login.header_candidate": "Candidate Login",
    "login.header_tan": "Candidate Login (TAN)",
    "login.header_testcenter": "Login",
    "login.login_as": "Login as:",
    "login.login_as_candidate": "Candidate",
    "login.login_as_proctor": "Proctor",
    "login.login_as_tan": "Candidate (TAN)",
    "login.login_as_testcenter": "Organisator",
    "login.metadata.button.cancel": "Cancel",
    "login.metadata.button.ok": "OK",
    "login.metadata.title": "Start",
    "login.placeholder.examid": "Exam",
    "login.placeholder.name": "Your name",
    "login.placeholder.password": "Password",
    "login.placeholder.username": "Username",
    "login.preparation_title": "Preparation Exam",
    "nyi": "Not yet implemented",
    "preparation.dashboard.button.delete": "Delete selected preparation exams (requires password)",
    "preparation.dashboard.button.info": "Info",
    "preparation.dashboard.button.new_tangroup": "Create new preparation exam",
    "preparation.dashboard.button.open": "Open",
    "preparation.dashboard.button.refresh": "Refresh",
    "preparation.dashboard.confirm.delete": "Do you really want to delete {{count}} tan groups?",
    "preparation.dashboard.confirm.delete_title": "Delete",
    "preparation.dashboard.dialog.new.allowance": "You can allocate up to {{remaining}} TANs",
    "preparation.dashboard.dialog.new.button.create": "Create",
    "preparation.dashboard.dialog.new.fields.module": "Module",
    "preparation.dashboard.dialog.new.fields.name": "Name",
    "preparation.dashboard.dialog.new.fields.password": "Password",
    "preparation.dashboard.dialog.new.fields.tans": "Tans",
    "preparation.dashboard.dialog.new.fields.valid_to": "Valid to",
    "preparation.dashboard.dialog.new.title": "New Exam",
    "preparation.dashboard.error.allowance": "You cannot create exam, because you already used {{used}} of {{max}}.",
    "preparation.dashboard.error.allowance_title": "Error",
    "preparation.dashboard.error.delete": "Unable to delete {{count}} tan groups",
    "preparation.dashboard.error.delete_title": "Error",
    "preparation.dashboard.error.invalid_password": "Invalid password!",
    "preparation.dashboard.error.invalid_password_title": "Error",
    "preparation.dashboard.error.password_needed": "You need to enter a password first!",
    "preparation.dashboard.error.password_needed_title": "Error",
    "preparation.dashboard.error.unable_to_create_new_exam": "Unable to create new exam!",
    "preparation.dashboard.error.unable_to_create_new_exam_title": "Error",
    "preparation.dashboard.filter.done_false": "Not done yet",
    "preparation.dashboard.filter.done_true": "Done",
    "preparation.dashboard.grid.done": "Done",
    "preparation.dashboard.grid.finished_count": "Finished candidates",
    "preparation.dashboard.grid.id": "(ID)",
    "preparation.dashboard.grid.module_shortname": "Module",
    "preparation.dashboard.grid.name": "Exam name",
    "preparation.dashboard.grid.password": "Enter password",
    "preparation.dashboard.grid.tan_count": "Exam TAN number",
    "preparation.dashboard.grid.valid_to": "Valid to",
    "preparation.dashboard.header": "Overview",
    "preparation.dashboard.label.testcenter": "Testcenter:",
    "preparation.dashboard.title": "Preparation Exam Administration",
    "preparation.tangroup.button.candidatepdf": "Result PDF",
    "preparation.tangroup.button.export": "Save list of TANs",
    "preparation.tangroup.button.finish": "Finish preparation exam and create results PDF",
    "preparation.tangroup.button.import": "Import names (TAN for...)",
    "preparation.tangroup.button.launch": "Jointly check preparation exam",
    "preparation.tangroup.button.printoverview": "Print overview",
    "preparation.tangroup.button.refresh": "Refresh",
    "preparation.tangroup.button.results": "Full results",
    "preparation.tangroup.confirm.close": "Do you want to close the tan group?",
    "preparation.tangroup.confirm.close_title": "Close",
    "preparation.tangroup.error.close": "Something went wrong while closing the tan group.",
    "preparation.tangroup.label.download_detail_report": "Download the detail report:",
    "preparation.tangroup.label.detailview": "Details:",
    "preparation.tangroup.detailview.objective": "{{id}} {{description}} ({{gained}}/{{max}})",
    "preparation.tangroup.hint.detailview": "Please click on the result row to see the details.",
    "preparation.tangroup.error.close_title": "Error",
    "preparation.tangroup.error.invalidxlsx": "Invalid XLSX File",
    "preparation.tangroup.error.invalidxlsx_title": "Error",
    "preparation.tangroup.error.launch": "Unable to launch the test",
    "preparation.tangroup.error.launch_title": "Error",
    "preparation.tangroup.grid.barometer": "Exam barometer",
    "preparation.tangroup.grid.file": "File",
    "preparation.tangroup.grid.for": "Name",
    "preparation.tangroup.grid.id": "(ID)",
    "preparation.tangroup.grid.incorrect": "Nr 'incorrect answer':",
    "preparation.tangroup.grid.items": "Items",
    "preparation.tangroup.grid.nr": "Nr",
    "preparation.tangroup.grid.result": "Results",
    "preparation.tangroup.grid.results": "Results (PDF)",
    "preparation.tangroup.grid.tan": "EXAM-TANs",
    "preparation.tangroup.grid.used": "TAN used",
    "preparation.tangroup.status.completed": "Completed",
    "preparation.tangroup.status.correct": "Correct",
    "preparation.tangroup.status.current": "Current",
    "preparation.tangroup.status.incorrect": "Incorrect",
    "preparation.tangroup.status.open": "Open",
    "preparation.tangroup.status.partiallycorrect": "Partially Correct",
    "preparation.tangroup.status.postponed": "Postponed",
    "preparation.tangroup.tab.overview": "Overview & TAN-Codes",
    "preparation.tangroup.tab.progress": "Progress & Evaluation",
    "preparation.tangroup.tab.results": "Conclusion & Results",
    "preparation.tangroup.tab.support": "Support",
    "preparation.tangroup.title": "Preparation Exam",
    "preparation.tangroup.valid_to": "Valid to",
    "preparation.tanoverview.button.print": "Print",
    "candidate.report.title": "Exam report",
    "candidate.report.exam": "Exam"
}
,
};
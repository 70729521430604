interface IShowableError<T> {
    displayMessage: string;
    displayTitle: string;
    interpolateParams?: T;
}
export class ShowableError<T> extends Error implements IShowableError<T> {
    constructor(message: string, readonly displayMessage: string = '', readonly displayTitle: string = '', readonly interpolateParams?: T) {
        super(message);
    }
}

export function isShowableError(err: object): err is IShowableError<any> {
    return err.hasOwnProperty('displayMessage') && err.hasOwnProperty('displayTitle');
}

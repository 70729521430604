<div class="error">
  <div *ngIf="info?.curVersion">
    <div class="title">{{'itsclient-required.version_mismatch_title' | translate:info }}</div>
    <div class="body" [innerHTML]="'itsclient-required.version_mismatch' | translate:info"></div>
    <div class="actions">
      <div *ngIf="installUrl">
        <dx-button text="{{'itsclient-required.action.install'|translate}}" (onClick)="installITSClient()"></dx-button>
        &nbsp;
      </div>
    </div>
  </div>
  <div *ngIf="!info?.curVersion">
    <div class="title">{{'itsclient-required.title' | translate:info }}</div>
    <div class="body" [innerHTML]="'itsclient-required.open' | translate:info"></div>
    <div class="actions">
      <dx-button text="{{'itsclient-required.action.open'|translate}}" (onClick)="openInClient()"></dx-button>
    </div>
    <div class="body" [innerHTML]="'itsclient-required.install' | translate:info"></div>
    <div class="actions">
      <div *ngIf="installUrl">
        <dx-button text="{{'itsclient-required.action.install'|translate}}" (onClick)="installITSClient()"></dx-button>
        &nbsp;
      </div>
    </div>
  </div>
</div>
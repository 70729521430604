export enum ITEM_STATUS {
    current = '↓',
    postponed = '◌',
    open = '○',
    completed = '●',
    correct = '+',
    incorrect = '-',
    partiallyCorrect = '±'
}


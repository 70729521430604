import * as semver from 'semver';

export function getITSClientVersion(this: void) {
  const version = /ITSR3\/([0-9]+\.[0-9]+\.[0-9])/;
  const vMatch = navigator.userAgent.match(version);
  if (!vMatch) {
    return undefined;
  }
  return vMatch[1];

}
export function checkITSClient(this: void, minITSClientVersion: string) {
  if (!minITSClientVersion) {
    return true;
  }
  const curVersion = getITSClientVersion();
  if (!curVersion) {
    return false;
  }
  return semver.gte(curVersion, minITSClientVersion);
}

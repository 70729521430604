import type DevExpress from 'devextreme/bundles/dx.all';
import { AS } from '../dx_helper';
import * as VALIDATE from './validate.mutation.g';

/** parse a regular expression - it must start with '/^' and end with '$/' (or end with '$/i');
    Valid expressions:
        /^$/
        /^[0-9]{3,6}$/
*/
function parseRegEx(possibleRegEx: string) {
    let pattern = possibleRegEx;
    let flags = '';
    if (!pattern.startsWith('/^')) {
        return undefined;
    }
    pattern = pattern.substr(1);
    if (pattern.endsWith('i')) {
        flags = 'i';
        pattern = pattern.substr(0, pattern.length - 1);
    }
    if (!pattern.endsWith('$/')) {
        return undefined;
    }
    pattern = pattern.substr(0, pattern.length - 1);
    try {
        const re = new RegExp(pattern, flags);
        return re;
    } catch {
        throw new Error(`Not a regular expression: ${possibleRegEx}`);
    }
}
type VALIDATE_TAN = NonNullable<VALIDATE.IValidateTanMutation['validateTan']>;
type METADATA = VALIDATE_TAN['metaData'][0];

export function addMetaDataFields(this: void,
    retVal: Array<DevExpress.ui.dxForm.SimpleItem>,
    metaData: METADATA[] | undefined) {
    if (!metaData) {
        return false;
    }
    if (!metaData.length) {
        return false;
    }
    for (const field of metaData) {
        if (field.__typename === 'MetaDataTextField') {
            const item = AS<DevExpress.ui.dxForm.SimpleItem>({
                dataField: `metadata.${field.key}`,
                label: {
                    text: field.label
                },
                editorType: 'dxTextBox',
                editorOptions: AS<DevExpress.ui.dxTextBox.Properties>({
                }),
                validationRules: [],
            });
            if (field.required) {
                item.validationRules!.push({
                    type: 'required',
                });
            }
            if (field.regex) {
                item.validationRules!.push({
                    type: 'pattern',
                    pattern: parseRegEx(field.regex),
                });
            }
            retVal.push(item);
        }
        if (field.__typename === 'MetaDataComboField') {
            const item = AS<DevExpress.ui.dxForm.SimpleItem>({
                dataField: `metadata.${field.key}`,
                label: {
                    text: field.label
                },
                editorType: 'dxSelectBox',
                editorOptions: AS<DevExpress.ui.dxSelectBox.Properties>({
                    dataSource: field.options,
                    valueExpr: 'value',
                    displayExpr: 'label'
                }),
                validationRules: [],
            });
            if (field.required) {
                item.validationRules!.push({
                    type: 'required'
                });
            }
            retVal.push(item);
        }

    }
    return true;
}

<div *ngIf="isLoading">
   <div class="invisible">Checking access</div>
</div>
<div *ngIf="!isLoading">
    <div *ngIf="isProctor" class="invisible">
        <h1>Proctor Login Page</h1>
        <p><button (click)="nav()">Continue to Dashboard</button></p>
    </div>
    <div *ngIf="!isProctor">
        <h1 class="invisible">{{ 'oidc.not-a-proctor.title' | translate }}</h1>
        <div class="error">
            <p>{{ 'oidc.not-a-proctor.message' | translate }}</p>
        </div>
        <p class="invisible"><button (click)="login()">Login</button></p>
    </div>
</div>
<section *ngIf="displayToken" style="width:100%">
    <h1>OIDC Token</h1>
    <textarea readonly style="width:100%;height:20rem">{{ token }}</textarea>
</section>
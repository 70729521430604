import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import * as Q from './config.query.g';
import * as Types from './its-organizer-api.types.g';

declare global {
    interface ITSR3 {
        connectToDonkey(serviceProvider: string, r3EndPoint: string): Promise<string>;
        setWindowMode(mode: 'compact' | 'normal', minWidth?: number, minHeight?: number): void;
    }

    interface ICefSharp {
        BindObjectAsync(name: 'itsr3'): Promise<any>;
    }
    interface Window {
        itsr3?: ITSR3;
        ITSR3_ARGS?: { [key: string]: string };
    }
    const CefSharp: ICefSharp | undefined;
}

export async function initITSR3(): Promise<void> {
    if (typeof CefSharp !== 'undefined') {
        console.log('We are running in an CefSharp Browser. Get the ITSR3 object');
        if (!window.itsr3) {
            const r = await CefSharp.BindObjectAsync('itsr3');
            console.dir(r);
        }
    }
}

@Injectable()
export class AppInitService {
    public requireITSR3Client: Types.ForceItsr3Client = Types.ForceItsr3Client.Always;
    public overrideForceITSR3Client?: Types.ForceItsr3Client;
    constructor(
        private readonly qSvc: Q.IGet_ConfigGQL,
        readonly translate: TranslateService,
    ) {
        const urlParams = new URLSearchParams(window.location.search);
        const itsr3client = urlParams.get('itsr3client') || '';
        this.overrideForceITSR3Client = itsr3client === 'no' ? Types.ForceItsr3Client.Never : undefined;
        if (this.overrideForceITSR3Client) {
            this.requireITSR3Client = this.overrideForceITSR3Client;
        }

    }
    async getSettings() {
        try {
            await initITSR3();
            if (typeof CefSharp !== 'undefined') {
                const isPopup = !!window.opener;
                if (window.itsr3 && !isPopup) {
                    console.log('we are running in ITSR3 client. set window mode to normal');
                    window.itsr3.setWindowMode('normal');
                }
            }
        } catch (e) {

        }
        const q = await firstValueFrom(this.qSvc.fetch({}));
        if (!this.overrideForceITSR3Client) {
            this.requireITSR3Client = q.data.config.forceITSR3Client;
        }
        return {
            lang: q.data.config.lang,
            newGroupTanCount: q.data.config.newTanGroupTans,
            hasPreparation: q.data.config.hasPreparation,
            hasCertification: q.data.config.hasCertification,
            supportIFrameUrl: q.data.config.supportIFrameUrl,
            autoRefreshInterval: q.data.config.autoRefreshInterval,
            blockRefresh: q.data.config.blockRefresh,
            instanceName: q.data.config.instanceName.value,
            hideObjectiveId: q.data.config.hideObjectiveId,
            tanMaxDays: q.data.config.tanMaxDays,
        };
    }

}

<div>
    <form (submit)="import($event)">
        <dx-form [(formData)]="formData" [items]="formItems" validationGroup="newExamForm">
            <div *dxTemplate="let data of 'fileTemplate'">
                <dx-file-uploader #uploader name="file" uploadMode="useForm"
                    (onValueChanged)="fileUploaderValueChanged($event)"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></dx-file-uploader>

                <div *ngIf="sampleXlsxUrl" style="padding:4px">
                    <dx-button icon="download" text="{{ 'newcertexam.button.sample' | translate }}"
                        (onClick)="downloadSample()"></dx-button>
                </div>
            </div>
        </dx-form>
        <div style="padding-top:2em">
            <dx-button type="default" [useSubmitBehavior]="true" validationGroup="newExamForm"
                text="{{ 'newcertexam.button.import' | translate }}">
            </dx-button>
        </div>
    </form>
</div>
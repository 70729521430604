<div>
  <div style="display:flex">
    <div (click)="itslogoClick()">
      <div class="itslogo"> </div>
    </div>
    <div style="flex:1;align-self: flex-end;">
      <div class="instanceName">{{instanceName}}</div>
    </div>
    <div>
      <div class="bitmedialogo"></div>
    </div>
  </div>
  <div style="height:25px;margin-top:15px; background-position: top;
background-repeat: no-repeat;
background-size: contain;
background-image: url(/assets/images/header_shadow.png);"></div>
</div>
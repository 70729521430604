<div class="dx-viewport">
  <div class="page-header">
    <div style="text-align: right;padding-right:20px"><img src="/assets/images/logoheader_its.png"></div>
    <div class="reportheader">Prüfungsbericht</div>
  </div>
  <table style="width:100%">
    <thead>
      <tr>
        <td>
          <div class="page-header-space"></div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>

          <table>
            <tr>
              <td><b>Testcenter:</b></td>
              <td>{{tcKey}} {{tcName}}</td>
            </tr>
            <tr>
              <td><b>Prüfungsdatum:</b></td>
              <td>{{examDate}}</td>
            </tr>
          </table>

          <div *ngFor="let module of modules" class="page">
            <div style="margin-top:2em;text-align:left"><span class="module">Prüfung {{module.name}}</span></div>
            <div>Für dieses Modul bekommt man bis zu {{formatNumber(module.scoreMax)}} Punkte.
              <span *ngIf="module.hasMastery">Mit {{formatNumber(module.mastery)}} Punkten oder mehr gilt die Prüfung als bestanden.</span>
            </div>
            <h3>Kandidaten und Kandidatinnen</h3>
            <table>
              <tr *ngFor="let candidate of module.candidates">
                <td style="height:22pt;font-family: Consolas, 'Courier New', Courier, monospace">{{candidate.reference}}
                </td>
                <td>
                  <app-score style="width:100px;display:block" [score]="candidate.score"></app-score>
                </td>
                <td>{{candidate.name}}</td>
                <td style="text-align: right">{{formatPercent(candidate.score)}}</td>
                <td>{{formatNumber(candidate.score!.gained)}} Punkte</td>
                <td>{{formatStatus(candidate)}}</td>
              </tr>
            </table>

            <h3>Punkteverteilung</h3>
            <table>
              <tr *ngFor="let obj of module.objectives">
                <td style="min-width:5em">{{obj.key}}</td>
                <td>
                  <app-score style="display:block;width:100px" [score]="obj.score"></app-score>
                </td>
                <td style="text-align: right">{{formatPercent(obj.score)}}</td>
                <td>{{obj.description}}</td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
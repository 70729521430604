import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import DevExpress from 'devextreme';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AS, DxFormItem, _ } from '../dx_helper';
import { extract_date } from '../helper';
import * as Types from '../its-organizer-api.types.g';
import { addMetaDataFields } from '../login/addMetaDataFields';
import { PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';
import * as NEW from './newexam.mutation.g';


type Testcenter = Q.INewcertexam_DataQuery['testcenters'][0];
type MetaData = Q.INewcertexam_DataQuery['examMetaData'][0];

@Component({
  selector: 'app-new-cert-exam',
  templateUrl: './new-cert-exam.component.html',
  styleUrls: ['./new-cert-exam.component.css']
})
export class NewCertExamComponent implements OnInit {
  public standaloneTC: Testcenter[] | undefined;
  public examMetaData: MetaData[] | undefined;
  public minDate: Date | undefined;
  public maxDate: Date | undefined;
  public sampleXlsxUrl: string | undefined;

  @Output()
  public readonly imported = new EventEmitter<{ id: string, key: string }>();

  public readonly formItems: DxFormItem[] = [];
  public formData: {
    testcenter?: string;
    examdate?: Date;
    file?: File;
    metadata: { [key: string]: string };
    name?: string;
  } = {
      metadata: {}
    };

  fileUploaderValueChanged(e: { value: File[] }) {
    this.formData.file = e.value && e.value[0];
  }
  async import(e: Event) {
    e.preventDefault();

    await this.svc.blockUI({
      action: async () => {
        const metadata = new Array<Types.IMetaDataValueInput>();
        const formMeta = this.formData.metadata || {};
        if (this.examMetaData && this.examMetaData.length) {
          for (const { key } of this.examMetaData) {
            const value = formMeta[key] || '';
            metadata.push({
              key,
              value
            });
          }
        }

        const result = await this.svc.networkactivity(() => firstValueFrom(this.newSvc.mutate({
          testcenterId: this.formData.testcenter ?? '',
          examDate: extract_date(this.formData.examdate ?? new Date()),
          xlsxFile: this.formData.file,
          name: this.formData.name || '',
          metadata,
        }, {
          context: {
            useMultipart: true
          }
        })));
        if (result.data) {
          this.imported.emit({
            id: result.data.insertExam.id,
            key: result.data.insertExam.key
          });
        }
      }
    });
  }

  constructor(
    private readonly newSvc: NEW.INewcertexam_NewGQL,
    private readonly qSvc: Q.INewcertexam_DataGQL,
    readonly svc: PreparationExamService,
    private readonly config: AppConfigService,
    readonly router: Router,
    private translate: TranslateService,
  ) { }

  async downloadSample() {
    if (!this.sampleXlsxUrl) {
      return;
    }
    window.open(this.sampleXlsxUrl);
  }

  async ngOnInit() {
    this.sampleXlsxUrl = window && window.__CONFIG && window.__CONFIG.CERT_SAMPLE_XLSX_URL || '';

    await this.refresh();

    this.minDate = moment().startOf('day').toDate();
    this.maxDate = moment().startOf('day').add(this.config.limitFutureExamDays(), 'days').toDate();
    this.formData.examdate = this.minDate;
    if (this.standaloneTC?.length) {
      this.formData.testcenter = this.standaloneTC[0].testcenterId;
    }

    const mode = this.config.getExamNameMode();
    if (mode !== 'hidden') {
      this.formItems.push({
        itemType: 'simple',
        label: {
          text: this.translate.instant(_('newcertexam.field.name')),
        },
        dataField: 'name',
        editorType: 'dxTextBox',
        validationRules: (mode === 'required') ? [{ type: 'required' }] : [],
      });
    }
    this.formItems.push({
      itemType: 'simple',
      label: {
        text: this.translate.instant(_('newcertexam.field.testcenter')),
      },
      dataField: 'testcenter',
      editorType: 'dxSelectBox',
      editorOptions: AS<DevExpress.ui.dxSelectBox.Properties>({
        dataSource: this.standaloneTC,
        valueExpr: 'testcenterId',
        displayExpr: 'name'
      }),
      validationRules: [{ type: 'required' }],

    });

    this.formItems.push({
      itemType: 'simple',
      dataField: 'examdate',
      label: {
        text: this.translate.instant(_('newcertexam.field.examdate')),
      },
      editorType: 'dxDateBox',
      editorOptions: AS<DevExpress.ui.dxDateBoxOptions>({
        min: this.minDate,
        max: this.maxDate,
      }),
      validationRules: [{ type: 'required' }],
    });

    addMetaDataFields(this.formItems, this.examMetaData);

    this.formItems.push({
      itemType: 'simple',
      label: {
        location: 'top',
        text: this.translate.instant(_('newcertexam.field.file')),
      },
      template: 'fileTemplate',
      validationRules: [{ type: 'required' }],
    });

  }

  async refresh() {
    const r = await firstValueFrom(this.qSvc.fetch({}));
    this.standaloneTC = r.data.testcenters;
    this.examMetaData = r.data.examMetaData;
  }
}

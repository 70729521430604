import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locale } from 'devextreme/localization';
import { firstValueFrom } from 'rxjs';
import { AppInitService } from './app-init.service';

export function init_app(this: void, appInitService: AppInitService, translate: TranslateService) {
    return async () => {
        const settings = await appInitService.getSettings();
        const lang = settings.lang || 'en';
        locale(lang);
        translate.setDefaultLang('en');
        await firstValueFrom(translate.use(lang));
        console.log(`Language: ${lang}`);
        return;
    };
}

@NgModule({
    providers: [
        AppInitService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: init_app,
            deps: [AppInitService, TranslateService]
        }
    ]
})
export class AppInitModule {

}

import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppInitService } from './app-init.service';
import * as Types from './its-organizer-api.types.g';
import { PreparationExamService } from './preparation-exam.service';

@Injectable()
export class NeedITSR3Guard implements CanActivate {

    constructor(
        @Optional() @Inject('user_agent') private userAgent: string,
        private svc: PreparationExamService,
        private initSvc: AppInitService,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.initSvc.requireITSR3Client === Types.ForceItsr3Client.Never) {
            return true;
        }
        let userAgent = this.userAgent;
        userAgent = window.navigator.appVersion;
        if (userAgent.indexOf('ITSR3/') !== -1) {
            return true;
        }
        const redirectUrl = this.router.url;

        this.svc.navigate(['/preparation-itsr3'], {
            redirectUrl
        });

        return false;
    }
}
